@charset "UTF-8";
/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */
/* ---- Global Variables ---- */
/*
    Breakpoint variables
*/
/*
    Variable Example
*/
/*
    Breakpoint Mixins
*/
/*
    Joint hover and Breakpoint Mixin
*/
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ---- Grow in fade ---- */
@-webkit-keyframes growInFade {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
@keyframes growInFade {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
/* ---- Base HTML ---- */
@viewport {
  width: device-width;
  zoom: 1;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

html,
body {
  background: #F0F0F0;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: 1.6rem;
  color: #4a4949;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.container {
  width: calc(100% - 1em);
  max-width: 1460px;
  margin: 0 auto;
  /* BP Smaller */
  /* BP small */
  /* BP medium */
  /* BP large */
  /* BP xlarge */
}
@media screen and (min-width: 22.5em) {
  .container {
    /* 360px */
    width: calc(100% - 2em);
  }
}
@media screen and (min-width: 30em) {
  .container {
    /* 480px */
    width: calc(100% - 3em);
  }
}
@media screen and (min-width: 35em) {
  .container {
    /* 560px */
  }
}
@media screen and (min-width: 48em) {
  .container {
    /* 768px */
    max-width: calc(48em - 3em);
  }
}
@media screen and (min-width: 62em) {
  .container {
    /* 992px */
    max-width: calc(62em - 3em);
  }
}
@media screen and (min-width: 75em) {
  .container {
    /* 1200px */
    max-width: calc(75em - 5em);
  }
}
@media screen and (min-width: 92em) {
  .container {
    /* 1472px */
    max-width: calc(92em - 5em);
  }
}

#wrap {
  overflow-x: hidden;
}

.bg-white {
  background-color: #ffffff;
}

.bg-lightgrey {
  background-color: #DADADA;
}

.bg-primary {
  background-color: #51b2e8;
}

.bg-secondary {
  background-color: #1e5792;
}

.bg-alternative {
  background-color: #53af48;
}

.bg-accent-01 {
  background-color: #FAA41A;
}

.bg-accent-02 {
  background-color: #ef373e;
}

.reverse-text {
  color: #ffffff;
}

.reverse-text a {
  color: inherit;
}

.reverse-text a:hover,
.reverse-text a:focus,
.reverse-text a:active {
  color: inherit;
  opacity: 0.75;
}

.section {
  padding-top: 16px;
  padding-bottom: 16px;
}

.section-small {
  padding-top: 15px;
  padding-bottom: 15px;
}

.section-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section__cta-btn {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
}
.section__cta-btn .button {
  padding-left: 32px;
  padding-right: 32px;
}

.section--t0 {
  padding-top: 0;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-small {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .section-large {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .section--t0 {
    padding-top: 0;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-small {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-large {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section__cta-btn {
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .section__cta-btn .button {
    min-width: 31.5%;
  }

  .section--t0 {
    padding-top: 0;
  }
}
.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb {
  margin-bottom: 20px;
}

.block-large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb-large {
  margin-bottom: 20px;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .block-large {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .block-mb-large {
    margin-bottom: 25px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .block-large {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-mb-large {
    margin-bottom: 30px;
  }
}
.box {
  padding: 20px 15px;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .box {
    padding: 20px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .box {
    padding: 30px;
  }
}
.box > *:last-child {
  margin-bottom: 0;
}

/* - Right - */
.col--right-align {
  text-align: right;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--right-align {
    text-align: right;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--right-align {
    text-align: right;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--right-align {
    text-align: right;
  }
}
/* - Left - */
.col--left-align {
  text-align: left;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--left-align {
    text-align: left;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--left-align {
    text-align: left;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--left-align {
    text-align: left;
  }
}
/* - center - */
.col--center-align {
  text-align: center;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--center-align {
    text-align: center;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--center-align {
    text-align: center;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--center-align {
    text-align: center;
  }
}
.left,
.center,
.right {
  margin: 30px auto;
  display: block;
  clear: both;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .left {
    margin: 0 30px 30px 0;
    float: left;
  }

  .right {
    margin: 0 0 30px 30px;
    float: right;
  }
}
/* ---- Text ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1.5rem 0;
  font-family: "roboto-slab", serif;
  font-weight: bold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

table + h1,
table + h2,
table + h3,
table + h4,
table + h5,
table + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 2.6rem;
  line-height: 3.2rem;
}

h3, .h3 {
  font-size: 2.1rem;
  margin-bottom: 0;
}

h4, .h4 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

h5, .h5 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

h6, .h6 {
  font-size: 1.6rem;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 1.5rem;
}

small {
  font-size: 1.28rem;
}

@media screen and (min-width: 22.5em) {
  /* 360px */
  h1, .h1 {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
}
@media screen and (min-width: 25em) {
  /* 400px */
  h1, .h1 {
    font-size: 3.2rem;
    line-height: 3.4rem;
  }

  h2, .h2 {
    font-size: 3.1rem;
    line-height: 4.8rem;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  h1, .h1 {
    font-size: 5.6rem;
    line-height: 5.6rem;
  }

  h2, .h2 {
    font-size: 3.2rem;
    line-height: 3.4rem;
  }

  h3, .h3 {
    font-size: 2.4rem;
    margin-bottom: 0.75rem;
  }

  h4, .h4 {
    font-size: 2rem;
  }

  h5, .h5 {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}
.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.two-part-heading {
  text-transform: uppercase;
  font-family: "roboto-slab", serif;
  color: #9c9c9c;
  line-height: 1.1;
  font-size: 1.8rem;
}
.two-part-heading__part-2 {
  display: block;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: #403f3f;
  font-size: 2.8rem;
}
.two-part-heading--reverse .two-part-heading__part-2 {
  color: #ffffff;
}
@media screen and (min-width: 48em) {
  .two-part-heading {
    /* 768px */
    font-size: 2.6rem;
  }
  .two-part-heading__part-2 {
    font-size: 4.3rem;
  }
}

p,
address {
  margin: 0 0 1.6rem;
}

/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #c6bfbf;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #f6f6f6;
  color: #473f3f;
  text-decoration: none;
}

mark {
  background-color: #f6f6f6;
  color: #473f3f;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: "Monaco", "Courier New", monospace;
  color: #484040;
  background: #efefef;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2rem 0;
  padding: 0;
}

ul,
ol dl {
  margin: 0 0 1.6rem;
  padding: 0 0 0 2rem;
}

li ul,
li ol {
  margin: 1rem 0;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol ul {
  list-style-type: disc;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ul.inline,
ol.inline {
  list-style-type: none;
  margin-left: 0;
}

ul.inline > li,
ol.inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

/* ---- Buttons and links ---- */
a {
  color: #1e5792;
  word-break: break-word;
  transition: all 250ms ease-in-out;
}

a:hover,
a:active,
a:focus {
  color: #0d253d;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

.button {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #51b2e8;
  text-decoration: none;
  border-radius: 0px;
  border: 2px solid transparent;
  outline: none;
  opacity: 1;
  position: relative;
  text-transform: lowercase;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
  transition: all 250ms ease-in-out;
  font-weight: 600;
}

.button:hover,
.button:focus,
.button:active {
  background-color: #dc8a05;
  color: #ffffff;
}

/* Small */
.button,
.button-small,
.button--small {
  font-size: 1.6rem;
  line-height: 1;
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

/* - Button icons - */
.button__icon {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
}

.button__icon--right {
  margin-left: 4px;
  margin-right: -2px;
}

.button__icon--left {
  margin-right: 4px;
  margin-left: -2px;
}

.button__icon svg {
  width: 16px;
  height: 16px;
}

.button__icon svg path {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
  fill: #51b2e8;
}

.button__icon--secondary svg path {
  fill: #1e5792;
}

.button:hover .button__icon--light-purple svg path {
  fill: #ffffff;
}

/* - Button styles - */
.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
  opacity: 1;
}

/* Alternative */
.button--alt {
  background-color: #dc8a05;
  color: #ffffff;
}

.button--alt:hover,
.button--alt:focus {
  background-color: #249ee2;
  color: #ffffff;
}

/* Subtle */
.button--subtle-grey {
  background-color: transparent;
  border: 2px solid #DADADA;
  text-transform: uppercase;
  font-weight: normal;
  color: #7f7f7f;
}

.button--subtle-grey:hover,
.button--subtle-grey:focus {
  background-color: #1e5792;
  border-color: #1e5792;
  color: #ffffff;
}

/* Subtle */
.button--subtle-white {
  background-color: transparent;
  border: 2px solid #DADADA;
  text-transform: uppercase;
  font-weight: normal;
  color: #ffffff;
}

.button--subtle-white:hover,
.button--subtle-white:focus {
  background-color: #1e5792;
  border-color: #1e5792;
  color: #ffffff;
}

/* Primary Subtle */
.button--subtle-primary {
  background-color: transparent;
  border: 2px solid #68bceb;
  text-transform: uppercase;
  color: #ffffff;
}

.button--subtle-primary:hover,
.button--subtle-primary:focus {
  background-color: #1e5792;
  border-color: #1e5792;
  color: #ffffff;
}

/* Secondary Subtle */
.button--subtle-secondary {
  background-color: transparent;
  border: 2px solid #2264a7;
  text-transform: uppercase;
  color: #1e5792;
}

.button--subtle-secondary:hover,
.button--subtle-secondary:focus {
  background-color: #1e5792;
  border-color: #1e5792;
  color: #ffffff;
}

/* Red Warning Button */
.button--warning {
  background-color: #9E0E0B;
  color: #ffffff;
}

.button--warning:hover,
.button--warning:focus {
  background-color: #6e0a08;
  color: #ffffff;
}

/* Grey */
.button--neutral {
  background-color: #DADADA;
  color: #403f3f;
}

.button--neutral:hover,
.button--neutral:focus {
  background-color: #b8b8b8;
  color: #403f3f;
}

.button--neutral svg path {
  fill: #403f3f;
}

/* Green */
.button--positive {
  background-color: #26883A;
  color: #ffffff;
}

.button--positive:hover,
.button--positive:focus {
  background-color: #0E6F22;
  color: #ffffff;
}

/* Block */
.button--block {
  display: block;
  width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
  border: none;
  opacity: 0.4;
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  /* Regular */
  .button {
    padding: 12px 16px 12px;
    line-height: 1;
  }

  .button-small,
.button--small {
    padding: 8px 12px 8px;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  /* Large */
  .button-large,
.button--large {
    font-size: 2.4rem;
    padding: 16px 32px;
  }

  .button--large .button__icon {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon svg {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon--right {
    margin-left: 4px;
    margin-right: -8px;
  }

  .button--large .button__icon--left {
    margin-right: 4px;
    margin-left: -8px;
  }
}
/* Legacy Grey */
.button.button-grey {
  background: #a8a8a8;
}

.button.button-grey:hover,
.button.button-grey:focus {
  background: #878787;
}

/* shopping cart button */
.button--cart {
  padding-right: 32px;
  background-image: url(../images/icon-system/icon_cart.svg);
  background-size: 16px auto;
  background-position: center right 12px;
  background-repeat: no-repeat;
  /* BP small */
}
@media screen and (min-width: 48em) {
  .button--cart {
    /* 768px */
    padding-right: 36px;
    background-position: center right 16px;
  }
}

.button-block {
  display: block;
  width: 100%;
}

/* ---- Images and Icons ---- */
.icon {
  display: inline-block;
  vertical-align: middle;
}
.icon--with-count {
  position: relative;
}
.icon__count {
  display: block;
  width: 18px;
  height: 18px;
  background: #ef373e;
  color: #ffffff;
  border-radius: 100px;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon path {
  transition: fill 250ms ease-in-out;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary svg path {
  fill: #51b2e8;
}

.icon--secondary svg path {
  fill: #1e5792;
}

.icon--alternative svg path {
  fill: #53af48;
}

.icon--white svg path {
  fill: #ffffff;
}

.icon--accent-01 svg path {
  fill: #FAA41A;
}

.icon--accent-02 svg path {
  fill: #ef373e;
}

/* ---- Video ---- */
.tinymce-media-iframe,
.widget-Video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tinymce-media-iframe {
  margin: 2rem 0;
}

iframe {
  border: 0;
}

/* ---- Tables ---- */
table {
  border-collapse: collapse;
  border: 1px solid #F0F0F0;
  width: 100%;
  border-collapse: collapse;
  margin: 8px 0 14px;
}

tr:first-child {
  border-top: 1px solid #F0F0F0;
}

tr:last-child {
  border-bottom: 1px solid #F0F0F0;
}

tr:nth-child(odd) {
  background-color: #FFF;
}

tr:nth-child(even) {
  background-color: #F0F0F0;
}

th,
td {
  padding: 5px 10px;
  border: none;
  background: transparent;
}

th {
  background-color: #F0F0F0;
}

thead td {
  background: transparent;
}

table .button {
  word-break: inherit;
}

/* ---- Stacked tables ---- */
@media screen and (max-width: 767px) {
  .table--stack,
.form-section {
    border-bottom: none;
    border: none;
  }

  .table--stack th,
.form-section th {
    display: none;
  }

  .table--stack,
.table--stack thead,
.table--stack tbody,
.table--stack tr,
.table--stack td,
.form-section,
.form-section thead,
.form-section tbody,
.form-section tr,
.form-section td {
    display: block;
    width: 100%;
  }

  .table--stack thead {
    display: none;
  }

  .table--stack tr,
.table--stack.table--content-standard tr,
.form-section tr {
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .table--stack tr td:first-child,
.form-section tr td:first-child {
    border-top: none;
  }

  .table--stack td,
.form-section td {
    border: none;
  }

  .table--stack td:empty,
.form-section td:empty {
    display: none;
  }

  .table--stack td[data-heading]:before,
.form-section td[data-heading]:before {
    content: attr(data-heading);
    display: block;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    font-weight: bold;
  }

  .table--stack.table--stack--horizontal th,
.form-section th {
    display: block;
  }
}
.table--content-standard {
  width: 100%;
  margin: 2rem 0;
  border-color: #F0F0F0;
  border-collapse: collapse;
}

.table--content-standard tr {
  border: none;
}

.table--content-standard tr:first-child {
  border-top: 1px solid #F0F0F0;
}

.table--content-standard tr:last-child {
  border-bottom: 1px solid #F0F0F0;
}

.table--content-standard tr:nth-child(even) {
  background-color: #FFF;
}

.table--content-standard tr:nth-child(odd) {
  background-color: #F0F0F0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: transparent;
  font-size: 1.7rem;
}

.table--content-standard td {
  background-color: transparent;
}

.table--content-standard th,
.table--content-standard td {
  border: none;
  text-align: left;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .table--responsive {
    font-size: 1.4rem;
  }

  .table--responsive,
.table--responsive tbody,
.table--responsive tr {
    display: block;
    border: none;
  }

  .table--responsive thead,
.table--responsive th {
    display: none;
  }

  .table--responsive tr {
    display: table;
    width: 100%;
  }

  .table--responsive tr.table--responsive__first-row {
    display: none;
  }

  .table--responsive td {
    display: table-row;
    padding: 0;
  }

  .table--responsive td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    background-color: #F0F0F0;
    border-right: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title] {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
  }

  .table--responsive tbody tr {
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title]:before,
.table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
/* ---- Helpers ---- */
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.-hidden {
  display: none !important;
  visibility: hidden;
}

.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@media only screen and (min-width: 35em) {
  /* 560px */
  .-vis-hidden--xsm {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .-vis-hidden--xsm.focusable:active, .-vis-hidden--xsm.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.-invisible {
  visibility: hidden;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

.mb-0 {
  margin-bottom: 0 !important;
}

/* ---- Text colours ---- */
.txt-primary {
  color: #51b2e8;
}

.txt-secondary {
  color: #1e5792;
}

/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */
/* ---- Text ---- */
blockquote {
  margin: 1.5rem 0;
  padding: 20px 25px 20px;
  background: #f6f6f6;
  font-style: italic;
  position: relative;
  clear: both;
}

blockquote *:first-child {
  margin-top: 0;
}

blockquote *:last-child {
  margin-bottom: 0;
}

blockquote:before,
blockquote:after {
  position: absolute;
  font-size: 13rem;
  display: block;
  height: 25px;
  width: 50px;
  color: #35ab75;
  font-style: italic;
  line-height: 10px;
  font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", Georgia, serif;
}

blockquote:before {
  content: "“";
  top: 38px;
  left: -8px;
}

blockquote:after {
  content: "”";
  bottom: -36px;
  right: 18px;
}

@media only screen and (min-width: 62em) {
  /* 992px */
  .blockquote--left {
    float: left;
    width: 45%;
    margin-left: 0px;
    margin-right: 20px;
    clear: right;
  }

  .blockquote--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    margin-right: 0px;
    clear: left;
  }
}
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
  clear: both;
}

/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
    clear: right;
  }

  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    clear: left;
  }
}
.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
  margin-bottom: 0;
}

.highlight--primary {
  background-color: #51b2e8;
  color: #fff;
}

.highlight--lined {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  border-left: 6px solid #51b2e8;
}

.highlight--lined {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
  clear: both;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  border-left: 6px solid #1e5792;
}

/* [01] */
.highlight--lined > *:last-child {
  margin-bottom: 0;
}

.js .expando {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.js .expando p:last-of-type {
  margin-bottom: 0;
}

/* - Open button - */
.expando-opener-para,
.expando-open {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 6px 0 3px 40px;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: #FAA41A;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  font-size: inherit;
  line-height: inherit;
  transition: color 250ms ease-in-out;
}

.expando-opener-heading {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 6px 0 3px 40px;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  color: #FAA41A;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  transition: color 250ms ease-in-out;
}

.expando-opener-para:before,
.expando-open:before,
.expando-opener-heading:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #F0F0F0;
  width: 26px;
  height: 26px;
  transform: translate(0, -50%);
  transition: all 250ms ease-in-out;
}

.expando-opener-para:after,
.expando-open:after,
.expando-opener-heading:after {
  content: " ";
  width: 14px;
  height: 14px;
  background: url(../images/icon-system/icon_form_plus.svg) no-repeat center center;
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  left: 6px;
  right: 0;
  margin: 0;
  transition: all 250ms ease-in-out;
}

.expando-opener-link,
.expando-opener-link.expanded {
  display: inline-block !important;
}

.expando-opener-para:after,
.expando-open:after,
.expando-opener-heading:after {
  transform: rotate(0deg);
}

.expando-opener-para.expanded:after,
.expando-open.expanded:after,
.expando-opener-heading.expanded:after {
  transform: rotate(45deg);
}

/* Hover */
.expando-open:hover,
.expando-open:focus {
  color: #dc8a05;
  text-decoration: underline;
}

.expando-open:hover:before,
.expando-opener-heading:hover:before {
  background-color: #DADADA;
}

/* - Close button - */
.expando__close {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  text-indent: -9999px;
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.expando__close:before,
.expando__close:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #565555;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 200ms ease;
}

.expando__close:before {
  transform: rotate(-45deg);
}

.expando__close:after {
  transform: rotate(45deg);
}

/* Hover */
.expando__close:hover:before,
.expando__close:hover:after {
  background-color: #2f2e2e;
}

/* - Expanded - */
.expando.expando--expanded {
  clip: auto;
  height: auto;
  margin: 20px 0 30px;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 25px 45px;
  background-color: #F0F0F0;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}

.expando-open--expanded .expando-open__icon:before {
  transform: rotate(-45deg);
}

.expando-open--expanded .expando-open__icon:after {
  transform: rotate(45deg);
}

ul.messages {
  list-style: none;
  margin: 16px 0 25px;
  padding: 0;
  border: 0px;
  background: none;
}

ul.messages li {
  margin: 0 0 15px;
  padding: 10px 20px;
  vertical-align: bottom;
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
}

ul.messages .error {
  background-image: none !important;
  background-color: #C32C2A !important;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}

ul.messages .confirm {
  color: #FFF !important;
  background: #26883A !important;
  border-left: 4px solid #0E6F22;
}

ul.messages .warning {
  color: #FFF !important;
  background: #CD4C1D !important;
  border-left: 4px solid #A72D00;
}

ul.messages a {
  color: #FFF;
}

span.field-error {
  background: none;
  color: #fff !important;
  background-color: #C32C2A !important;
  border-left: 4px solid #9E0E0B;
  font-size: 1.4rem;
  border-right: none;
  padding: 8px 10px 8px 10px;
  display: inline-block;
}

.error span.field-error {
  background: none;
  color: #fff;
  font-weight: bold;
  border-right: none;
  padding: 0;
  margin: 0px;
  font-size: 1.5rem;
  vertical-align: middle;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
}

.tooltip__title {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: all 100ms ease-in-out;
  border-bottom: 1px dotted #7f7f7f;
}

.tooltip__title a {
  color: inherit;
}

.tooltip__title a:not(.button):hover,
.tooltip__title a:not(.button):focus {
  color: #1e5792;
}

.tooltip:hover .tooltip__title {
  opacity: 0.7;
}

.tooltip__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #404041;
  max-width: 270px;
  min-width: 120px;
  padding: 10px 10px;
  font-size: 1.1rem;
  border-radius: 4px;
  margin-top: 7px;
  text-align: center;
  pointer-events: none;
  color: #FFF;
  z-index: 98;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
}

.tooltip__content small {
  font-size: 1.1rem;
}

.tooltip__content:before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #404041 transparent;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: #404041 transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  -webkit-animation: fadeIn 200ms ease;
  animation: fadeIn 200ms ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  border-radius: 10px;
  background: #dcd9d1;
  color: #FFF;
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

/* BP Large */
@media only screen and (min-width: 80em) {
  /* 1280px */
  .tooltip__content {
    max-width: 400px;
    min-width: 180px;
    font-size: 1.4rem;
  }
}
/* ---- Lists ---- */
.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0px;
  margin-left: -16px;
  margin-right: -16px;
}

.social-list__item {
  display: inline-block;
  padding: 0 16px;
  margin-right: -8px;
  border-left: 2px solid #DADADA;
}
.social-list__item:first-child {
  border-left: 0;
}
.social-list__item a {
  color: inherit;
  display: block;
}
.social-list__item svg {
  display: block;
}
.social-list__item svg path {
  transition: fill 200ms ease-in-out;
}

.social-list--mobile {
  border-top: 1px solid #f0f0f0;
  padding-left: 0;
  margin: 0;
}
@media screen and (min-width: 62em) {
  .social-list--mobile {
    /* 992px */
    display: none;
  }
}
.social-list--mobile .social-list__item {
  border-left: 0;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.social-list--mobile .social-list__item svg {
  width: 20px;
  height: 20px;
}
.social-list--mobile .social-list__item svg path {
  fill: #51b2e8;
}
.social-list--mobile .social-list__item a:hover svg path, .social-list--mobile .social-list__item a:focus svg path, .social-list--mobile .social-list__item a:active svg path {
  fill: #51b2e8;
}

.social-list--topnav {
  position: relative;
  top: -1px;
}
.social-list--topnav .social-list__item {
  border-left: 0;
  padding: 0 8px;
  height: 22px;
  margin-right: -2px;
}
.social-list--topnav .social-list__item a {
  display: inline-block;
}
.social-list--topnav .social-list__item svg {
  width: 16px;
  height: 16px;
}
.social-list--topnav .social-list__item svg path {
  fill: #ffffff;
}
.social-list--topnav .social-list__item:last-child {
  border-right: 2px solid #51b2e8;
}
.social-list--topnav .social-list__item a:hover svg path, .social-list--topnav .social-list__item a:focus svg path, .social-list--topnav .social-list__item a:active svg path {
  fill: #51b2e8;
}

.children-gallery-list {
  list-style-type: none;
  margin: 20px -15px 10px;
  padding: 0;
}

.widget-ChildrenGallery .children-gallery-list-item {
  margin: 15px;
  max-width: 100%;
  position: relative;
  background-color: #F0F0F0;
  width: calc(50% - 30px);
  float: left;
}

.children-gallery-list-item a {
  display: block;
  width: 100%;
}
.children-gallery-list-item a:before {
  content: "";
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  height: 10px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.15);
}
.children-gallery-list-item a:after {
  content: "";
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 100%;
  height: 10px;
  z-index: 1;
  background: #1e5792;
  transition: all 400ms ease-in-out;
}
.children-gallery-list-item a:hover:after, .children-gallery-list-item a:focus:after, .children-gallery-list-item a:active:after {
  right: 0;
}

.widget-ChildrenGallery .children-gallery-list-item-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
  margin-bottom: 12px;
}

.children-gallery-list-item-image-placeholder {
  margin-bottom: 10px;
  position: relative;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
  opacity: 0.9;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
  margin: 0;
  font-size: 1.6rem;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  font-family: "roboto-slab", serif;
  font-weight: bold;
  transition: all 400ms ease-in-out;
}
@media screen and (min-width: 38.125em) {
  .widget-ChildrenGallery .children-gallery-list-item-title {
    /* 610px */
    font-size: 2rem;
  }
}

.widget-ChildrenGallery .children-gallery-list-item-anchor {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@supports (display: grid) {
  .children-gallery-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    row-gap: 1.6rem;
    column-gap: 2.4rem;
    justify-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .children-gallery-list.-clearfix:before,
.children-gallery-list.-clearfix:after {
    content: none;
  }

  .children-gallery-list-item,
.widget-ChildrenGallery .children-gallery-list-item {
    max-width: 100%;
    width: 100%;
    margin: 0;
    float: none;
    margin-bottom: 0;
    background-color: transparent;
  }

  .widget-ChildrenGallery .children-gallery-list-item-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .widget-ChildrenGallery .children-gallery-list-item-title {
    order: 1;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0px;
    padding-bottom: 8px;
    line-height: 1.12;
    flex: 1 auto;
  }

  .widget-ChildrenGallery .children-gallery-list-item-image {
    order: 2;
    margin-bottom: 0;
  }

  /* BP Smaller */
  @media screen and (min-width: 38.125em) {
    /* 610px */
    .children-gallery-list {
      column-gap: 3.6rem;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }
}
.widget-FileList {
  background: #DADADA;
  padding: 20px;
  margin-bottom: 16px;
}

.widget-FileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-FileList li {
  margin-bottom: 8px;
}

.widget-FileList li:last-child {
  margin-bottom: 0;
}

.widget-FileList .document {
  min-height: 24px;
  padding-left: 32px;
  background: url(../../images/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
  background-image: url(../../images/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
  background-image: url(../../images/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
  background-image: url(../../images/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
  background-image: url(../../images/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
  background-image: url(../../images/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
  background-image: url(../../images/icon-system/icon_video.svg);
}

.widget-FileList a {
  display: inline-block;
  text-decoration: none;
  color: #51b2e8;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(81, 178, 232, 0);
  transition: all 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
  text-decoration: none;
  border-bottom: 1px solid #51b2e8;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .widget-FileList {
    padding: 30px;
  }
}
.block-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.block-list li {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.block-list a {
  display: block;
  text-decoration: none;
  padding: 7px 0;
}

/* ---- Navigation ---- */
.breadcrumb {
  list-style: none;
  margin: 8px 0 16px;
  padding: 0;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 22.5em) {
  .breadcrumb {
    /* 360px */
    margin: 8px 0 24px;
  }
}
.breadcrumb a {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  color: white;
  text-decoration: none;
  margin-right: 20px;
  position: relative;
}
.breadcrumb a:after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: -12px;
  width: 1px;
  background: rgba(255, 255, 255, 0.6);
}
.breadcrumb a:hover, .breadcrumb a:focus, .breadcrumb a:active {
  color: white;
  border-bottom: 1px solid #ffffff;
}
@media screen and (max-width: 34.9375em) {
  .breadcrumb {
    /* 559px */
  }
  .breadcrumb a,
.breadcrumb a:after,
.breadcrumb span {
    display: none;
  }
  .breadcrumb a:last-of-type {
    display: inline;
  }
  .breadcrumb a:last-of-type:before {
    content: "<";
    padding-right: 4px;
  }
}

.search-paginate {
  text-align: center;
  padding: 1.5rem;
  background: #b8b8b8;
}

.search-paginate .page {
  display: inline-block;
  background: #FAA41A;
  color: #fff;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  text-align: center;
  margin: 0 0.25rem;
}

.search-paginate .page.on {
  background: #aa6a04;
}

.search-paginate .page-next {
  margin-left: 1.5rem;
}

.search-paginate .page-prev {
  margin-right: 1.5rem;
}

@supports (display: flex) {
  .search-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.section--nav {
  background: #1e5792;
  padding: 0;
}

/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/
/** Desktop and Mobile styling
===================================*/
.extra-mobile-menu-items,
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

.extra-mobile-menu-items a,
#frankenmenu-list a {
  text-decoration: none;
  display: block;
  font-size: 1.6rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 992px) {
  .extra-mobile-menu-items {
    display: none;
  }

  .primary-nav {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  #frankenmenu {
    z-index: 1000;
  }

  #frankenmenu-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: calc(100% + 16px);
    margin-inline: -8px;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }

  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    -webkit-box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    text-align: center;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.3rem;
    padding: 8px 0 6px;
    margin: 0 8px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(81, 178, 232, 0);
  }

  /* Hover */
  #frankenmenu-list .menu-item-depth1 > a:hover,
#frankenmenu-list .menu-item-depth1 > a:focus,
#frankenmenu-list .menu-item-depth1.frankenhover > a,
#frankenmenu-list .menu-item-depth1.frankenhold > a {
    color: #51b2e8;
    border-bottom: 2px solid #51b2e8;
  }

  /* Current item */
  #frankenmenu-list .menu-item-depth1.menu-current-item > a {
    border-color: white;
    color: white;
  }

  /* Current ancestor */
  #frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
    border-color: rgba(255, 255, 255, 0.5);
    color: white;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu a {
    border-top: none;
    border-left: 3px solid #fff;
    padding: 12px 10px 12px;
    color: #4a4949;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }

  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #fff;
    color: #000;
    border-color: #51b2e8;
    padding-left: 15px;
  }

  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }

  #frankenmenu-list .sub-menu .menu-item.menu-current-item > a {
    border-color: #51b2e8;
    color: #000;
    padding-left: 15px;
  }

  /* ---- First level submenus ---- */
  #frankenmenu-list .sub-menu-depth1 {
    top: 100%;
    left: 16px;
  }

  /* ---- Right aligned submenus ---- */
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }

  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }

  #frankenmenu-subnav {
    float: right;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    padding: 5px 5px;
    display: block;
    color: inherit;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: underline;
  }

  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }

  /* ---- Megamenu ---- */
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #fff;
    color: #4a4949;
    text-align: left;
    top: 100%;
    top: calc(100% - 32px);
    position: absolute;
    border-bottom: none;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  /* Remove relative positioning for mega menu */
  #frankenmenu-list.mega .menu-item {
    position: static;
  }

  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 36px 0 0;
    width: 100vw;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
    box-shadow: 0 36px 36px rgba(0, 0, 0, 0.25);
  }

  .scrolled #frankenmenu-list .menu-item.frankenhover .mega-menu {
    top: calc(100% - 12px);
  }

  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }

  #frankenmenu-list .menu-item .mega-menu .container {
    padding: 0 0 36px;
  }

  /* Custom Mega Menu - preview row  */
  #frankenmenu-list .mega-menu-preview-row {
    margin-bottom: 8px;
  }

  /* Megamenu preview */
  #frankenmenu-list .mega-menu-preview {
    float: left;
    color: #484848;
    padding: 0;
    width: 22%;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-preview-image {
    height: 174px;
    background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
    background-size: cover;
  }

  #frankenmenu-list .mega-menu-preview-title {
    color: #484848;
    display: inline-block;
    margin-bottom: 0;
    font-size: 2.4rem;
  }

  #frankenmenu-list .mega-menu-preview-text {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.2;
    padding-left: 1em;
    margin-left: 1em;
    border-left: 1px solid #979797;
  }

  .mega-menu-column-title {
    font-size: 2rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  /* Megamenu columns */
  #frankenmenu-list .mega-menu .mega-menu-columns {
    float: left;
    width: 75%;
  }

  #frankenmenu-list .mega-menu-preview--collapsed + .mega-menu-columns {
    width: 100%;
  }

  #frankenmenu-list .mega-menu-columns--1 .mega-menu-column,
#frankenmenu-list .mega-menu-columns--2 .mega-menu-column {
    width: 48%;
    float: left;
    margin-right: 4%;
  }

  #frankenmenu-list .mega-menu-columns--3 .mega-menu-column {
    width: 31.333%;
    float: left;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-columns--4 .mega-menu-column {
    width: 22%;
    float: left;
    margin-right: 4%;
  }

  #frankenmenu-list .mega-menu-columns--2 .mega-menu-column:last-child,
#frankenmenu-list .mega-menu-columns--3 .mega-menu-column:last-child,
#frankenmenu-list .mega-menu-columns--4 .mega-menu-column:last-child {
    margin-right: 0;
  }

  /* Megamenu submenus */
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #D8D8D8;
  }

  #frankenmenu-list .mega-menu-submenu .menu-item:first-child {
    border-top: none;
  }

  #frankenmenu-list .mega-menu-submenu a {
    color: #000;
    padding: 5px 0;
  }

  #frankenmenu-list .mega-menu-submenu a:after {
    content: " ";
    display: inline-block;
    width: 10px;
    height: 1.2em;
    background-image: url(../images/icon_chevron-right.svg);
    background-repeat: no-repeat;
    background-size: 6px auto;
    background-position: bottom right;
  }

  #frankenmenu-list .mega-menu-submenu a:hover,
#frankenmenu-list .mega-menu-submenu a:focus {
    color: #4a4949;
  }

  #frankenmenu-list .mega-menu-submenu a:hover:after,
#frankenmenu-list .mega-menu-submenu a:focus:after {
    -webkit-animation: arrows-slide 0.3s cubic-bezier(0.28, 0, 0.72, 1) normal;
    animation: arrows-slide 0.3s cubic-bezier(0.28, 0, 0.72, 1) normal;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}
/** Mobile menu only
===================================*/
@media all and (max-width: 991px) {
  /* Prevents font-boosting of menu items on Android */
  #frankenmenu * {
    max-height: 999999px;
  }

  #wrap {
    margin-top: 106px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #FFF;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
    z-index: 1;
  }

  .scrolled #wrap {
    margin-top: 60px;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #fff;
    width: 70%;
    z-index: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
    padding-top: 106px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0);
    z-index: 2;
  }

  .scrolled #frankenmenu {
    padding-top: 60px;
  }

  .frankenmenu-mob-menu-visible #frankenmenu {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.2);
  }

  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background-color: #1e5792;
    height: 106px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border-bottom: 10px solid #B1C2D4;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  .scrolled #mobile-header {
    height: 60px;
  }

  #mobile-header .row {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    align-items: center;
    height: 50px;
  }

  #mobile-header .header__logo {
    margin-top: 12px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 120px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  .mobile-header__login-btn {
    height: 36px;
    padding: 8px 12px 8px;
  }

  .scrolled #mobile-header .header__logo {
    margin-top: 6px;
  }

  #mobile-header .header__logo img {
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
    transform: scale(1);
    transform-origin: top left;
  }

  .scrolled #mobile-header .header__logo img {
    transform: scale(0.5);
  }

  #mobile-header .header__logo a {
    display: block;
  }

  #mobile-header .header__topnav__cart {
    margin-right: 1.75em;
    margin-top: 2px;
  }

  #mobile-header .header__topnav__cart__label {
    display: inline;
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-left: 1em;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #fff;
    left: 6px;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopClose;
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleClose;
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomClose;
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopOpen;
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleOpen;
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomOpen;
    animation-name: mobileMenuBtnBottomOpen;
  }

  @-webkit-keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }

  .extra-mobile-menu-items .menu-item,
#frankenmenu-list .menu-item {
    border-top: 1px solid #f0f0f0;
    float: none;
    width: 100%;
    position: relative;
  }

  .extra-mobile-menu-items .menu-item {
    border-bottom: 1px solid #f0f0f0;
  }

  .extra-mobile-menu-items a,
#frankenmenu-list a {
    min-height: 39px;
    width: 100%;
    padding-top: 9px;
    padding-right: 55px;
    padding-bottom: 9px;
    color: #2a333e;
    border-left: 3px solid transparent;
  }

  #frankenmenu-list .menu-item.menu-current-item > a {
    color: #2a333e;
    font-weight: bold;
    border-left: 3px solid #51b2e8;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.5rem;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }

  /* Depth differences */
  .extra-mobile-menu-items .menu-item-depth1 > a,
#frankenmenu-list .menu-item-depth1 > a {
    padding-left: 17px;
    background-color: #fff;
  }

  #frankenmenu-list .menu-item-depth2 a {
    font-size: 1.4rem;
  }

  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 27px;
  }

  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 37px;
  }

  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 47px;
  }

  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 57px;
  }

  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 57px;
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    color: #4a4949;
    font-size: 2.3rem;
    width: 55px;
    background: none;
    height: 39px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }

  .submenu-toggle:before,
.submenu-toggle:after {
    content: " ";
    width: 12px;
    height: 3px;
    display: block;
    position: absolute;
    background-color: #4a4949;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 22px;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  .submenu-toggle:before {
    -webkit-transform: rotate(45deg) translateX(-5px);
    -ms-transform: rotate(45deg) translateX(-5px);
    transform: rotate(45deg) translateX(-5px);
  }

  .submenu-toggle:after {
    -webkit-transform: rotate(-45deg) translateX(5px);
    -ms-transform: rotate(-45deg) translateX(5px);
    transform: rotate(-45deg) translateX(5px);
  }

  .menu-item-submenu-open > .submenu-toggle:before,
.menu-item-submenu-open > .submenu-toggle:after {
    top: 15px;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    -webkit-transform: rotate(-45deg) translateX(-5px);
    -ms-transform: rotate(-45deg) translateX(-5px);
    transform: rotate(-45deg) translateX(-5px);
  }

  .menu-item-submenu-open > .submenu-toggle:after {
    -webkit-transform: rotate(45deg) translateX(5px);
    -ms-transform: rotate(45deg) translateX(5px);
    transform: rotate(45deg) translateX(5px);
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(70%, 0, 0);
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: -70%;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #frankenmenu {
    right: 0;
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu,
#wrap,
#frankenmenu .container {
    -webkit-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
  }

  .js #frankenmenu {
    opacity: 0;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview,
.mega-menu-preview-row {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #FFF;
    font-size: 1.6rem;
    line-height: inherit;
    padding: 6px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }

  .menu__link__search svg {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  .menu-item-depth1--item6 + .menu-item {
    display: none;
  }
}
@media screen and (max-width: 34.9375em) {
  /* 559px */
  #mobile-header .header__topnav__cart__label {
    display: none;
  }

  #mobile-header .header__topnav__cart {
    margin-right: 10px;
    width: 32px;
    text-align: center;
  }
}
/* BP large */
@media screen and (min-width: 75em) {
  /* 1200px */
  #frankenmenu-list .menu-item-depth1 > a {
    margin: 0 16px;
  }

  #frankenmenu-list .menu-item-depth1:first-child > a {
    margin-left: 0;
  }

  #frankenmenu-list .menu-item-depth1:last-child > a {
    margin-right: 0;
  }
}
@media screen and (min-width: 92em) {
  /* 1472px */
  .primary-nav {
    width: calc(100% - 140px);
    margin-top: 0;
  }

  #frankenmenu-list {
    display: flex;
    justify-content: flex-end;
  }

  /* ---- megamenu ---- */
  #frankenmenu-list .mega-menu {
    left: 0;
  }

  #frankenmenu-list .menu-item .mega-menu .container {
    padding-left: 5em;
    padding-right: 5em;
  }
}
.widget-RelatedLinks {
  margin-bottom: 25px;
}

.widget-RelatedLinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-RelatedLinks a {
  text-decoration: none;
  color: #2a333e;
  display: block;
  padding: 9px 0;
}

/* - Depth 1 - */
.widget-RelatedLinks li.depth1 {
  border-bottom: 1px solid #e6e1e1;
  position: relative;
}

.widget-RelatedLinks li.depth1:last-child {
  border-bottom: none;
}

.widget-RelatedLinks li.depth1 > a {
  padding: 9px 0;
  position: relative;
}

.widget-RelatedLinks li.depth1 > a:hover,
.widget-RelatedLinks li.depth1 > a:focus,
.widget-RelatedLinks li.depth1 > a:active {
  color: #000000;
}

.widget-RelatedLinks li.depth1:before {
  content: "";
  background: #DADADA;
  position: absolute;
  height: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: 280ms ease-in-out;
}

.widget-RelatedLinks li.depth1:hover:before {
  height: 4px;
}

/* On */
.widget-RelatedLinks li.depth1.on {
  border-left: 3px solid #51b2e8;
}

.widget-RelatedLinks li.depth1.on:before {
  display: none;
}

.widget-RelatedLinks li.depth1.on > a {
  padding-left: 20px;
  font-weight: bold;
}

/* - Depth 2 - */
.widget-RelatedLinks ul.depth2 {
  padding-bottom: 8px;
}

.widget-RelatedLinks li.depth2 {
  font-size: 1.4rem;
}

.widget-RelatedLinks li.depth2 a:before {
  content: "- ";
  display: inline-block;
  width: 12px;
  position: relative;
  left: -12px;
  margin-right: -12px;
}

.widget-RelatedLinks li.depth2 a {
  transition: text-decoration-color 200ms ease-in-out;
  text-decoration: underline;
  text-decoration-color: rgba(81, 178, 232, 0);
}

.widget-RelatedLinks li.depth2:hover a {
  text-decoration: underline;
  text-decoration-color: #51b2e8;
}

.widget-RelatedLinks li.depth2 a {
  padding: 4px 0 4px 34px;
  border-bottom: 2px solid rgba(218, 218, 218, 0);
}

/* Hover */
.widget-RelatedLinks li > a:hover {
  color: #000;
}

/* On */
.widget-RelatedLinks li.depth2.on > a {
  color: #51b2e8;
  font-weight: bold;
}

/* ---- Plugins / third-party styles ---- */
/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
  max-width: 1000px;
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  background-color: #fff;
  margin: 0 auto;
  padding: 50px;
  max-width: 800px;
}

#facebox .content {
  padding: 20px;
  background: none;
}

.popup .content {
  border-bottom: none;
  margin-bottom: 16px;
}
@media screen and (min-width: 75em) {
  .popup .content {
    grid-template-columns: auto;
    grid-gap: 0;
  }
}

.popup-content__title {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #DADADA;
}

/* ---- Blocks ---- */
.btn-filter {
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
.btn-filter__title {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 100;
  margin: 0 8px 0 0;
  white-space: nowrap;
}
.btn-filter__list {
  list-style-type: none;
  margin: -2px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}
.btn-filter__list li {
  display: block;
  padding-right: 0;
  margin: 2px;
}
.btn-filter__list__btn {
  font-weight: 100;
  background-color: transparent;
  border: 2px solid #DADADA;
  text-transform: uppercase;
  color: #7f7f7f;
  line-height: 1;
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-filter__list__btn:hover, .btn-filter__list__btn:focus, .btn-filter__list__btn:active, .btn-filter__list__btn--on {
  background: #51b2e8;
  border-color: #51b2e8;
  color: #ffffff;
}

.date-card {
  background: #51b2e8;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  max-width: 94px;
  line-height: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
}
.date-card__day {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
}
:where(a).media-object--date:hover .date-card, :where(a).media-object--date:focus .date-card, :where(a).media-object--date:active .date-card {
  background-color: #1e5792;
}

.date-card--small {
  padding: 8px;
  max-width: 70px;
}
.date-card--small .date-card__day {
  font-size: 3.6rem;
}

.tile {
  background: #ffffff;
}

.tile__inner {
  height: 100%;
}

.tile__img img {
  width: 100%;
}

.tile__content {
  padding: 20px 25px;
}

.banner-basic {
  background: #000;
  color: #ffffff;
  position: relative;
  height: calc((100vw - 1em) * 0.55);
}
@media screen and (min-width: 22.5em) {
  .banner-basic {
    /* 360px */
    height: calc((100vw - 2em) * 0.5851);
  }
}
@media screen and (min-width: 30em) {
  .banner-basic {
    /* 480px */
    height: calc((100vw - 3em) * 0.5851);
  }
}
@media screen and (min-width: 35em) {
  .banner-basic {
    /* 560px */
    height: auto;
  }
}
@media screen and (min-width: 62em) {
  .banner-basic {
    /* 992px */
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    background: #51b2e8;
  }
}
.banner-basic__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  opacity: 0.5;
  overflow: hidden;
}
.banner-basic__img img {
  object-fit: cover;
}
@media screen and (min-width: 62em) {
  .banner-basic__img {
    /* 992px */
    position: relative;
    opacity: 1;
    order: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25.4%;
    flex: 0 0 25.4%;
  }
  .banner-basic__img img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}
.banner-basic__text {
  padding-top: 8px;
  padding-left: 1em;
  padding-right: 1em;
  background: rgba(0, 0, 0, 0.6);
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}
@media screen and (min-width: 22.5em) {
  .banner-basic__text {
    /* 360px */
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 35em) {
  .banner-basic__text {
    /* 560px */
    height: auto;
  }
}
@media screen and (min-width: 48em) {
  .banner-basic__text {
    /* 768px */
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media screen and (min-width: 62em) {
  .banner-basic__text {
    /* 992px */
    background: transparent;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
@media screen and (min-width: 75em) {
  .banner-basic__text {
    /* 1200px */
    padding-left: 5em;
    padding-right: 5em;
  }
}
.banner-basic__text__section-title {
  margin-bottom: 0;
  opacity: 0.6;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "roboto-slab", serif;
  font-size: 1.6rem;
  line-height: 1;
  -webkit-box-flex: 1;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
}
@media screen and (min-width: 25em) {
  .banner-basic__text__section-title {
    /* 400px */
    font-size: 2.2rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 48em) {
  .banner-basic__text__section-title {
    /* 768px */
    font-size: 2.8rem;
  }
}
.banner-basic__text__page-title {
  margin: 0;
}
.banner-basic__text__page-title__deco {
  position: relative;
  display: inline-block;
  padding-bottom: 24px;
}
@media screen and (min-width: 48em) {
  .banner-basic__text__page-title__deco {
    /* 768px */
    padding-bottom: 32px;
  }
}
.banner-basic__text__page-title__deco:after {
  content: " ";
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #7ec6ee;
}
@media screen and (min-width: 35em) {
  .banner-basic__text__page-title--sm {
    font-size: 3.6rem;
    line-height: 1.2;
  }
}
.banner-basic__text div {
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-top: auto;
}
.banner-basic__text .breadcrumb {
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

.image-gallery {
  margin: 0.5em;
}

.image-gallery-thumb {
  width: 100%;
  display: inline-block;
  float: left;
}

.image-gallery-caption {
  margin-top: 4px;
}

.image-gallery-popup {
  display: block;
}

.image-gallery-thumb img {
  width: 100%;
}

.image-gallery--captions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@supports (display: grid) {
  .image-gallery {
    margin: 0;
  }

  .image-gallery-thumb {
    width: auto;
    margin: 0;
  }

  .image-gallery {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
    content: none;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .image-gallery-thumb {
    width: calc(50% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .image-gallery-thumb {
    width: calc(33% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .image-gallery-thumb {
    width: calc(25% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
.media-object {
  padding: 20px 0;
  width: 100%;
  float: left;
}

:where(a).media-object {
  text-decoration: none;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object__heading {
  color: #51b2e8;
}

.media-object__content__textarea {
  margin-top: 8px;
}

.media-object__content__textarea p {
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.media-object__details {
  font-weight: bold;
  color: #7f7f7f;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  /* 768px */
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }
  .media-object--date .media-object__img {
    width: 70px;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
  .media-object--date .media-object__content {
    width: calc(100% - 72px);
  }
}
.card {
  background: #ffffff;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 200ms ease-in-out;
  color: inherit;
  text-decoration: none;
}
.card:hover {
  color: inherit;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}
.card:focus-within {
  color: inherit;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}
.card a:focus {
  text-decoration: underline;
}
.card:focus-within a:focus {
  text-decoration: none;
}
.card__heading {
  color: #51b2e8;
}
.card__heading a {
  color: inherit;
  text-decoration: none;
}
.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
  padding: 2rem;
  /* BP small */
}
@media screen and (min-width: 48em) {
  .card__content {
    /* 768px */
    padding: 3rem;
  }
}
.card__desc {
  margin-top: 8px;
}
.card__img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.card__cta {
  display: inline-block;
  white-space: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0px;
  border: 2px solid #DADADA;
  outline: none;
  opacity: 1;
  text-transform: uppercase;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #4a4949;
  line-height: 1;
  font-size: 1.6rem;
  line-height: 1;
  padding: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 200ms ease-in-out;
}
.card:hover .card__cta, .card:focus-within .card__cta, .card a:focus .card__cta {
  background: #51b2e8;
  border-color: #51b2e8;
  color: #ffffff;
}

.card--event:hover, .card--event:focus-within {
  box-shadow: none;
}
.card--event .card__img {
  padding-top: 66%;
  background: no-repeat center center;
  background-size: cover;
  position: relative;
}
.card--event .date-card {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 94px;
}
.card--event .card__content {
  background: #F0F0F0;
  padding-top: 48px;
  position: relative;
}
@media screen and (min-width: 25em) {
  .card--event .card__content {
    /* 400px */
    padding: 48px 24px 24px;
  }
}
@media screen and (min-width: 48em) {
  .card--event .card__content {
    /* 768px */
    display: flex;
    justify-content: flex-end;
  }
}
.card--event .card__tagline {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 94px;
  background: #565555;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 8px;
  z-index: 3;
}
.card--event .card__heading {
  color: #51b2e8;
  font-size: 2.6rem;
  line-height: 1.2;
  flex: 1 auto;
}
.card--event .card__desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}
@media screen and (min-width: 25em) {
  .card--event .card__desc {
    /* 400px */
    grid-gap: 3rem;
  }
}
.card--event .card__desc h4 {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
}
.card--event .card__desc p {
  margin-bottom: 0;
}
.card--event .card__desc__when {
  padding-right: 10px;
  border-right: 2px solid #b8b8b8;
}
.card--event:hover .card__cta, .card--event:hover .card__cta, .card--event:hover .card__cta, .card--event .card__cta {
  border-color: #abdaf4;
  margin-top: 16px;
  display: block;
  order: 2;
}
.card--event:hover .card__cta:after, .card--event:hover .card__cta:after, .card--event:hover .card__cta:after, .card--event .card__cta:after {
  content: "!";
}
.card--event .card__hover {
  /* BP large */
}
@media screen and (min-width: 75em) {
  .card--event .card__hover {
    /* 1200px */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #51b2e8;
    color: #fff;
    padding: 48px 24px 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    opacity: 0;
  }
  .card--event .card__hover:hover, .card--event .card__hover:focus, .card--event .card__hover:active {
    opacity: 1;
  }
}
.card--event .card__hover__text {
  /* BP large */
}
@media screen and (min-width: 75em) {
  .card--event .card__hover__text {
    /* 1200px */
    display: block;
    color: #ffffff;
    clip: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: unset;
    width: auto;
    order: 1;
    font-family: "roboto-slab", serif;
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 1.2;
  }
}

.card--icon {
  position: relative;
  min-height: 250px;
  height: 100%;
  background: #51b2e8;
  border-bottom: 6px solid #249ee2;
}
.card--icon:hover, .card--icon:focus-within {
  box-shadow: none;
}
.card--icon .card__content {
  color: #ffffff;
  text-align: center;
}
.card--icon .card__heading {
  color: #ffffff;
  margin-bottom: 8px;
}
.card--icon .card__heading a {
  color: #ffffff;
  text-decoration: none;
}
.card--icon .card__cta, .card--icon:hover .card__cta {
  color: #ffffff;
  border-color: #abdaf4;
}
.card--icon .card__img {
  position: absolute;
  top: 0;
  bottom: -6px;
  left: 0;
  right: 0;
  height: auto;
  z-index: 1;
  background: #F0F0F0;
  padding: 1em;
  transition: all 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
  border-bottom: 6px solid #DADADA;
  /* BP small */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .card--icon .card__img {
    /* 768px */
    padding: 1.5em;
  }
}
.card--icon .card__img:after {
  display: block;
  text-align: center;
  content: attr(data-heading);
  font-size: 2rem;
  color: #51b2e8;
  font-family: "roboto-slab", serif;
  /* BP small */
}
@media screen and (min-width: 48em) {
  .card--icon .card__img:after {
    /* 768px */
    font-size: 2.4rem;
  }
}
.card--icon .card__img__icon {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card--icon .card__img__icon svg {
  width: 80px;
  height: 80px;
}
.card--icon:hover .card__img, .card--icon:focus-within .card__img {
  opacity: 0;
  z-index: -2;
}

.card--stats {
  color: #51b2e8;
  background: #F0F0F0;
  padding: 0;
  font-weight: bold;
  text-align: center;
  border-bottom: 6px solid #DADADA;
}
.card--stats:hover, .card--stats:focus, .card--stats:active {
  color: #51b2e8;
  box-shadow: unset;
}
.card--stats .card__content {
  padding-bottom: 1.5em;
}
.card--stats .card__img__icon {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  margin-bottom: 12px;
  background-color: pink;
}
.card--stats .card__number {
  font-size: 4.8rem;
  line-height: 1.12;
}
.card--stats .card__number__desc {
  margin-block: 12px 0;
  display: block;
  font-size: 1.8rem;
  line-height: 1.12;
}

.card--slider {
  background: #51b2e8;
  text-decoration: none;
  display: block;
  color: #ffffff;
}
.card--slider:hover, .card--slider:focus, .card--slider:active {
  color: #ffffff;
  box-shadow: none;
}
.card--slider .two-part-heading__part-2 {
  color: #ffffff;
  font-family: "roboto-slab", serif;
  font-weight: bold;
}
.card--slider .card__img {
  background: no-repeat center center;
  background-size: 100% auto;
  padding-top: 50.5%;
  height: auto;
  position: relative;
  transition: background 400ms ease-in-out;
}
.card--slider:hover .card__img, .card--slider:focus .card__img, .card--slider:active .card__img {
  background-size: 110% auto;
}
.card--slider .card__heading {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 2rem;
  padding-bottom: 1rem;
  margin: 0;
}
.card--slider .card__heading:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: #abdaf4;
}
@media screen and (min-width: 48em) {
  .card--slider .card__heading {
    /* 768px */
    left: 3rem;
    padding-bottom: 2.5rem;
  }
}
.card--slider .card__heading:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 100%;
  height: 10px;
  background: #1e5792;
  transition: all 400ms ease-in-out;
}
.card--slider:hover .card__heading:after, .card--slider:focus .card__heading:after, .card--slider:active .card__heading:after {
  right: 0;
}
.card--slider .card__subheading {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.8rem;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .card--slider .card__subheading {
    /* 768px */
    font-size: 4.3rem;
  }
}
.card--slider .card__content {
  align-items: flex-start;
  padding-top: 3rem;
  padding-bottom: 6rem;
}
@media screen and (min-width: 48em) {
  .card--slider .card__content {
    /* 768px */
    padding-bottom: 12rem;
  }
}
.card--slider .card__cta {
  min-width: 240px;
  color: #ffffff;
}
@media screen and (min-width: 48em) {
  .card--slider .card__cta {
    /* 768px */
    padding: 12px;
    font-size: 1.8rem;
  }
}
.card--slider:hover .card__cta {
  background: #abdaf4;
  color: #1e5792;
  transition: all 400ms ease-in-out;
}

.card--simple:hover {
  box-shadow: none;
}
.card--simple:focus-within {
  box-shadow: none;
}
.card--simple .card__cta {
  margin-top: 16px;
}
.card--simple .card__heading {
  flex: 1 0 auto;
}

.card--product {
  background: #F0F0F0;
}
.card--product:hover {
  box-shadow: none;
}
.card--product:focus-within {
  box-shadow: none;
}
.card--product .card__img {
  height: auto;
}
.card--product .card__img img {
  object-fit: auto;
  width: 200px;
  margin: auto;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

.slick-slider {
  margin: 0;
  padding: 0;
  min-height: 100px;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent no-repeat center center;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.slick-prev {
  background-image: url(../images/slider-left-arrow.svg);
  background-size: 16px auto;
  left: 0px;
}

[dir=rtl] .slick-prev {
  right: 0px;
  left: auto;
}

.slick-next {
  background-image: url(../images/slider-right-arrow.svg);
  background-size: 16px auto;
  right: 0;
}

[dir=rtl] .slick-next {
  right: auto;
  left: 0;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .slick-prev {
    left: 25px;
    background-size: auto auto;
  }

  [dir=rtl] .slick-prev {
    left: auto;
  }

  .slick-next {
    right: 25px;
    background-size: auto auto;
  }

  [dir=rtl] .slick-next {
    left: 25px;
  }
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -55px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 45px;
  height: 45px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  overflow: hidden;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  content: "";
  opacity: 0.25;
  background: #000000;
  border-radius: 20px;
  transition: all 250ms ease-in-out;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  background: #000000;
}

.slider-clean {
  position: relative;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  height: auto;
  text-align: center;
}

.slider__button {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0 4px;
}

.slider__button[type=button] {
  -webkit-appearance: unset;
}

.slider__button a {
  display: block;
  font-size: 1.2rem;
  line-height: 2;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background: rgba(0, 0, 0, 0.66);
  color: #ffffff;
  text-decoration: none;
}

.slider__gallery {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: auto;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -webkit-scroll-snap-points-y: repeat(100vw);
  -ms-scroll-snap-points-y: repeat(100vw);
  scroll-snap-points-y: repeat(100vw);
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.slider__item {
  display: inline-block;
  scroll-snap-align: start;
  float: left;
  width: 100vw;
  flex-shrink: 0;
  height: 100%;
  scroll-behavior: smooth;
}

.slider__item img {
  object-fit: cover;
}

@supports (scroll-snap-type: mandatory) {
  .slider__buttons {
    display: none;
  }
}
/* Arrows */
.card-slider .slick-arrow {
  top: 23vw;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .card-slider .slick-arrow {
    top: 30%;
  }
}
/* ---- Forms ---- */
/* ---- Placeholders ---- */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a29999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a29999;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a29999;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #a29999;
}

.field-element--dropdown--placeholder .field-input select {
  color: #a29999;
}

/* All form elements with preceeding icon */
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox,
.field-element--money .field-input .textbox,
.field-element--timepicker .field-input .textbox {
  padding-left: 52px;
}

/* Clearfix all field-elements */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

/* ---- form colour varients ---- */
/* -- default - grey -- */
.textbox,
input[type=text],
.field-input input,
.field-input textarea,
.field-input select {
  border: 1px solid #9c9c9c;
  border-radius: 0px;
  background-color: #F0F0F0;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
  outline: #565555 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
  background-color: #ffffff;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type=checkbox] + label,
.fieldset-input.fieldset-input--disabled input[type=checkbox] + label,
.field-element--disabled input[type=radio] + label,
.fieldset-input.fieldset-input--disabled input[type=radio] + label {
  cursor: not-allowed;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element--hidden-label .field-label.focusable:active, .field-element--hidden-label .field-label.focusable:focus,
.field-element--hidden-label .fieldset__legend.focusable:active,
.field-element--hidden-label .fieldset__legend.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Required ---- */
.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0.3em;
  margin-bottom: 0;
}

/* ---- Field errors ---- */
.field-element.field-element--error .field-input .textbox,
.field-element.field-element--error .field-input select {
  background-color: #fde8e8;
}

.field-error__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.field-error__list__item {
  color: #ffffff;
  background: #9E0E0B;
  padding: 10px 20px;
  margin: 11px 0;
  border-left: 6px solid #C32C2A;
}

/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
  position: relative;
}

.field-element-attach-wrapper__button {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background: #51b2e8;
  border: none;
  outline: none;
  padding: 0 7px;
  z-index: 1;
  cursor: pointer;
  min-width: 45px;
}

.field-element-attach-wrapper__button svg {
  vertical-align: middle;
}

.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
  background-color: #9c9c9c;
}

.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
  padding-right: 55px;
}

/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
  background-color: #DADADA;
}

.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
  background-color: #b8b8b8;
}

/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before, .daterangepicker.opensright:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c9c9c;
  border-left: 8px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.field-element--totalselector__dropdown:after, .daterangepicker.opensright:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

form h1,
form h2,
form h3,
form h4,
form h5 {
  margin-top: 1em;
}

/* ---- Flex ---- */
.field-elements-flex {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex: 1 1 100%;
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  max-width: calc(100% + 4px);
  margin-left: -2px;
  margin-right: -2px;
}
.field-elements-flex:before, .field-elements-flex:after {
  content: none;
}
.field-elements-flex .field-element {
  float: none;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 8px;
  padding-left: 2px;
  padding-right: 2px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  max-width: 100%;
}
.field-elements-flex .field-element--checkboxset {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

@media only screen and (min-width: 35em) {
  /* 560px */
  .field-elements-flex .field-element {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
  }

  .field-elements-flex button[type=submit],
.field-elements-flex .field-element--button {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
@media only screen and (min-width: 48em) {
  /* 768px */
  .field-elements-flex {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .field-elements-flex .field-element,
.field-elements-flex .field-element--grow {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
  }

  .field-elements-flex button[type=submit],
.field-elements-flex .field-element--button,
.field-elements-flex .field-element--shrink {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
.button-flex-field {
  height: 44px;
  width: 44px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-flex-field svg path {
  fill: #fff;
}

.form-section {
  width: 100%;
}

.form-section {
  width: 100%;
  border-color: #F0F0F0;
  border-collapse: collapse;
  margin: 8px 0 14px;
}

.form-section tr {
  border: none;
}

.form-section tr:first-child {
  border-top: 1px solid #F0F0F0;
}

.form-section tr:last-child {
  border-bottom: 1px solid #F0F0F0;
}

.form-section tr:nth-child(even) {
  background-color: #FFF;
}

.form-section tr:nth-child(odd) {
  background-color: #F0F0F0;
}

.form-section th,
.form-section td {
  padding: 8px 12px;
  border: none;
}

.form-section th {
  text-align: left;
  font-weight: bold;
  padding-right: 8px;
  background-color: transparent;
  width: 30%;
}

.form-section td {
  text-align: left;
}

.field-info {
  width: 80px;
  font-size: 1.4rem;
}

span.field-info {
  font-weight: bold;
  padding: 4px;
  display: inline-block;
}

.form-section .textbox,
.form-section .field-input input,
.form-section .field-input textarea,
.form-section select {
  padding: 4px 8px;
}

.form-section select {
  border: 1px solid #9c9c9c;
  background-color: #F0F0F0;
}

.form-section .textbox,
.form-section select,
.form-section .field-input input,
.form-section .field-input textarea,
.form-section .field-input select {
  background-color: #fff;
}

th.radio,
td.radio {
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  width: 40px;
  border-right: 1px solid #f0f0f0;
}

.field-element--colorpicker .textbox.colorpicker {
  padding: 2px 8px;
  height: 40px;
  width: 48px;
}

.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
  position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_datepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 0px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #9c9c9c;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #9c9c9c;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #DADADA;
}

.daterangepicker .calendar-table {
  border: 1px solid #b8b8b8;
  padding: 4px;
  border-radius: 0px;
  background: #ffffff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #565555;
  background: #F0F0F0;
  border-color: #DADADA;
}

.daterangepicker td.off.in-range {
  background-color: #F0F0F0;
}

.daterangepicker td.off.active {
  background-color: #7f7f7f;
  color: #ffffff;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #565555;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #5497dc;
}

.daterangepicker td.in-range {
  background: #69a3e0;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #1e5792;
  color: #ffffff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #9c9c9c;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #ffffff;
  outline: none;
  border-radius: 0px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #9c9c9c;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
  padding: 1px 2px;
}

.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-image: url(../images/icon-system/icon_form_arrow-right.svg);
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-image: url(../images/icon-system/icon_form_arrow-left.svg);
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F0F0F0;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #9c9c9c;
  border-radius: 0px;
  color: #4a4949;
  display: block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin: 0 0 4px 0;
  padding: 0 16px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #FAA41A;
  background-color: #F0F0F0;
}

.daterangepicker .input-mini:focus {
  outline: 4px auto #FAA41A;
  outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #4a4949;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #FAA41A;
  color: #ffffff;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #dc8a05;
  color: #ffffff;
}

.daterangepicker .cancelBtn {
  background-color: #b8b8b8;
  color: #2f2e2e;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #9f9f9f;
  color: #2f2e2e;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #DADADA;
  border: 1px solid #F0F0F0;
  color: #2f2e2e;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #1e5792;
  border: 1px solid #1e5792;
  color: #ffffff;
}

@supports (display: grid) {
  .range_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 160px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker.single .calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
.daterangepicker.single .calendar {
    float: left;
  }

  .daterangepicker .calendar.right {
    margin-left: 0;
  }

  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }

  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }

  .daterangepicker .ranges,
.daterangepicker .calendar {
    float: left;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
  position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_datetimepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* fieldset styles */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.field-label,
.fieldset__legend {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 8px;
  line-height: 1.2;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}

.field-element--checkboxlist--columns .field-element__input-set {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
}

/* Hide checkbox and radio field element) */
.field-element input[type=checkbox],
.field-element input[type=radio],
input.pseudo-anchor[type=checkbox],
input.pseudo-anchor[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element input[type=checkbox].focusable:active, .field-element input[type=checkbox].focusable:focus,
.field-element input[type=radio].focusable:active,
.field-element input[type=radio].focusable:focus,
input.pseudo-anchor[type=checkbox].focusable:active,
input.pseudo-anchor[type=checkbox].focusable:focus,
input.pseudo-anchor[type=radio].focusable:active,
input.pseudo-anchor[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.field-element input[type=checkbox] + label,
.field-element input[type=radio] + label,
.field-element--small input[type=checkbox] + label,
.field-element--small input[type=radio] + label,
input[type=checkbox] + .pseudo,
input[type=radio] + .pseudo {
  padding-left: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

input[type=checkbox] + .pseudo,
input[type=radio] + .pseudo {
  padding-left: 0;
}

/* -- Field set pseudo elements -- */
.field-element input[type=checkbox] + label:before,
.field-element input[type=radio] + label:before,
.field-element--small input[type=checkbox] + label:before,
.field-element--small input[type=radio] + label:before,
input[type=checkbox] + label.pseudo:before,
input[type=radio] + label.pseudo:before {
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  position: relative;
  top: 4px;
  background-color: #F0F0F0;
  border: 1px solid #9c9c9c;
  margin-left: -32px;
}

input[type=checkbox] + label.pseudo:before,
input[type=radio] + label.pseudo:before {
  margin-left: 0;
  margin-right: 0;
}

/* Radio button (pseudo element) */
.field-element input[type=radio] + label:before,
.field-element--small input[type=radio] + label:before,
input[type=radio] + label.pseudo:before {
  border-radius: 12px;
}

/* -- Field set pseudo elements : checked -- */
.field-element input[type=checkbox]:checked + label:after,
.field-element input[type=radio]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after,
input[type=checkbox]:checked + label.pseudo:after,
input[type=radio]:checked + label.pseudo:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 6px;
}

/* Checkbox box checked (pseudo element) */
.field-element input[type=checkbox]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
input[type=checkbox]:checked + label.pseudo:after {
  content: " ";
  background-image: url(../images/icon-system/icon_form_tick-md.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 24px;
  height: 24px;
}

/* Radio dot (pseudo element) */
.field-element input[type=radio]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after,
input[type=radio]:checked + label.pseudo:after {
  left: 7px;
  top: 11px;
  width: 9.6px;
  height: 9.6px;
  border-radius: 4.8px;
  background: #4a4949;
}

/* White */
.field-element--white input[type=checkbox] + label:before,
.field-element--white input[type=radio] + label:before {
  background-color: #ffffff;
}

/* input focus state */
.fieldset--multiradio:focus-within {
  outline: #565555 auto 5px;
}

.field-element input[type=checkbox]:focus + label {
  outline: #565555 auto 5px;
  outline-offset: -3px;
}

.checkbox-list {
  border: none;
  border-color: #F0F0F0;
  border-collapse: collapse;
  width: 100%;
}

.checkbox-list tr:first-child {
  border-top: none;
}

.checkbox-list tr:last-child {
  border-bottom: none;
}

.checkbox-list tr,
.checkbox-list tr:nth-child(even),
.checkbox-list tr:nth-child(odd) {
  background-color: transparent;
}

.checkbox-list td,
.checkbox-list th {
  padding: 2px;
}

.checkbox-list td.check {
  width: 20px;
}

.event-form-check {
  display: inline-block;
  margin-right: 10px;
}

.event-form-checkdesc {
  display: inline-block;
}

.checkbox-list .check + .name p {
  margin-bottom: 0;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤";
  /* Generic currency sign */
  position: absolute;
  display: block;
  left: 2px;
  top: calc(50% - 14px);
  color: #4a4949;
  border-right: 1px solid #9c9c9c;
  font-weight: bold;
  font-size: 1.92rem;
  width: 40px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$";
  /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£";
  /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥";
  /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹";
  /* Indian rupee */
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #4a4949;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #FAA41A;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #FAA41A;
  box-shadow: 2px 2px 4px #4a4949;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input select {
  -moz-appearance: none;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-input select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-input select::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
  padding: 8px;
  background-image: none;
}

.field-element--select--multiple option {
  padding: 8px;
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #4a4949;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #FAA41A;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #FAA41A;
  box-shadow: 2px 2px 4px #4a4949;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #303030;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #4a4949;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.textbox,
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  padding: 10px 16px 10px;
  -webkit-appearance: none;
  line-height: 1.4;
}

input[type=text] {
  outline: none;
  line-height: normal;
  padding: 10px 16px 10px;
  -webkit-appearance: none;
  line-height: 1.4;
}

/* time picker */
.field-element--timepicker .field-input {
  position: relative;
}

.field-element--timepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_timepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Timepicker modal */
.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
  z-index: 9999 !important;
}

.ui-timepicker-standard {
  background-color: #ffffff;
  border: 1px solid #9c9c9c;
  display: block;
  margin: 4px 0 0;
  padding: 6px;
  list-style: none outside none;
  border-radius: 0px;
}

.ui-timepicker-standard a {
  color: #2f2e2e;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #DADADA;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 0px;
}

.ui-timepicker-hidden {
  display: none;
}

.field-element--totalselector {
  position: relative;
}

.field-element--totalselector .field-input .total-selector__output {
  cursor: pointer;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: 2px;
  border-radius: 4px;
  z-index: 800;
  border: 1px solid #9c9c9c;
  display: none;
  white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 1px solid #7f7f7f;
  border-radius: 0px;
  outline: none;
  background: none;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #565555;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
  background-image: url(../images/icon-system/icon_form_minus.svg);
  background-position: left 8px -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
  background-image: url(../images/icon-system/icon_form_plus.svg);
  background-position: right 8px -1 center;
}

.total-selector__dropdown__field__button:focus {
  color: #565555;
  border-color: #565555;
  background-color: #F0F0F0;
}

.total-selector__dropdown__field__button:hover {
  color: #565555;
  border-color: #565555;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
  opacity: 0.5;
  pointer-events: none;
  color: #565555;
  border-color: #b8b8b8;
}

.total-selector__dropdown__field__total {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 4px 6px;
  width: 50px;
  text-align: center;
  font-size: 1.8rem;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title {
  margin: 0;
  font-size: 1.6rem;
}

.total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: 1.2rem;
}

.field-element--totalselector__fields {
  display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
  text-align: right;
  padding-top: 10px;
}

/* BP Extra small */
@media only screen and (min-width: 37.5em) {
  /* 600px */
  .field-element--totalselector__dropdown {
    right: auto;
    min-width: 320px;
  }

  .total-selector__dropdown__field__labels__helper {
    font-size: 1.5rem;
    line-height: 1;
  }

  .total-selector__dropdown__field {
    margin: 0 0 24px;
  }
}
/* BP Small */
@media only screen and (min-width: 48.063em) {
  /* 769px */
  /* Don't really need this close button above tablet */
  .total-selector__dropdown__close__button-wrap {
    display: none;
  }
}
.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 0px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  background: #000;
  padding: 12px 16px;
  border: 1px solid #9c9c9c;
  border-radius: 0px;
  background-color: #F0F0F0;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #565555 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.submit-bar {
  text-align: right;
  background: #DADADA;
  padding: 16px;
  margin: 16px 0;
}

.sidebar .submit-bar {
  padding: 12px 0;
}

.submit-bar a {
  margin-right: 20px;
}

.submit-bar--sticky {
  position: fixed;
  z-index: 9000;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  padding-bottom: 40px;
  -webkit-backface-visibility: hidden;
  transition: all 250ms ease-in-out;
}
.submit-bar--sticky.scrolled-down {
  padding-bottom: 40px;
}
.submit-bar--sticky.scrolled-up {
  padding-bottom: 20px;
}
@media screen and (min-width: 62em) {
  .submit-bar--sticky {
    /* 992px */
  }
  .submit-bar--sticky.scrolled-down {
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 75em) {
  .submit-bar--sticky {
    /* 1200px */
  }
  .submit-bar--sticky .container {
    padding-left: 5em;
    padding-right: 5em;
  }
}

.site-search-form {
  margin: 0 0 40px;
}
.site-search-form:before, .site-search-form:after {
  content: " ";
  display: table;
}
.site-search-form:after {
  clear: both;
}
.site-search-form input {
  max-width: calc(100% - 95px);
  float: left;
}
.site-search-form input[type=submit] {
  max-width: none;
  max-width: unset;
  float: right;
}

.site-search-form--header {
  margin: 0;
}

.search-result {
  border-top: 1px solid #9c9c9c;
  margin: 20px 0;
  padding: 20px 0;
}

.search-result p:last-child {
  margin-bottom: 0;
}

.g-recaptcha {
  padding-bottom: 15px;
}

.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 0px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  padding: 4px 8px;
  border: 1px solid #9c9c9c;
  border-radius: 0px;
  background-color: #F0F0F0;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #565555 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #DADADA;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #DADADA;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--upload-inline {
  background-color: #F0F0F0;
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.field-element--upload-inline label {
  display: block;
  cursor: pointer;
  font-weight: bold;
}
.field-element--upload-inline label:after {
  content: "select";
  font-weight: normal;
  background: #1e5792;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 0px;
  margin: 0 0 0 16px;
  transition: all 250ms ease-in-out;
}
.field-element--upload-inline label:hover:after {
  content: "select";
  background-color: #113153;
}
.field-element--upload-inline input:focus + label,
.field-element--upload-inline input:active + label {
  outline: #565555 auto 5px;
}
.field-element--upload-inline input[type=file] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/* ---- A single field and submit form ---- */
.sub-enews-form {
  border-radius: 100px;
  overflow: hidden;
}
.sub-enews-form:hover, .sub-enews-form:focus, .sub-enews-form:active {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
}
.sub-enews-form .field-input input {
  border: 0px solid #9c9c9c;
}
.sub-enews-form .field-element-attach-wrapper__button {
  top: 0px;
  right: 0px;
  bottom: 0px;
  padding: 0 24px 0 16px;
  background: #ffffff;
}
.sub-enews-form:hover .field-element-attach-wrapper__button, .sub-enews-form:focus .field-element-attach-wrapper__button, .sub-enews-form:active .field-element-attach-wrapper__button {
  background: #51b2e8;
}
.sub-enews-form:hover .field-element-attach-wrapper__button svg path, .sub-enews-form:focus .field-element-attach-wrapper__button svg path, .sub-enews-form:active .field-element-attach-wrapper__button svg path {
  fill: #ffffff;
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
.section--header {
  background: #1e5792;
  color: #ffffff;
  position: relative;
  padding: 32px 0 32px;
  display: none;
  transition: all 250ms ease-in-out;
}
.scrolled .section--header {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media screen and (min-width: 62em) {
  .section--header {
    /* 992px */
    display: block;
  }
}
.section--header:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: rgba(30, 87, 146, 0.3);
  transition: all 250ms ease-in-out;
}
.scrolled .section--header:after {
  height: 6px;
}
.section--header .header__logo {
  display: none;
}
@media screen and (min-width: 62em) {
  .section--header .header__logo {
    /* 992px */
    display: block;
    max-width: 73px;
  }
}
@media screen and (min-width: 92em) {
  .section--header .header__logo {
    /* 1472px */
    max-width: 133px;
  }
}
.section--header .header__logo a {
  display: block;
}

@media screen and (min-width: 92em) {
  .container--header {
    /* 1472px */
    padding-left: 5em;
    padding-right: 5em;
  }
}

.header__logo {
  float: left;
}
.header__topnav {
  float: right;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.header__topnav :where(ul) {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.header__topnav :where(ul) li {
  display: inline-block;
}
.header__topnav__list__item {
  border-right: 2px solid #51b2e8;
  padding: 0 16px;
}
.header__topnav__list__item--members {
  border: 0px;
  padding-right: 0;
  text-align: left;
}
.header__topnav__list__item--members :where(ul) {
  display: block;
}
@media screen and (min-width: 35em) {
  .header__topnav__list__item--members {
    /* 560px */
  }
  .header__topnav__list__item--members :where(ul) {
    display: inline-block;
  }
}
.header__topnav__link {
  color: #ffffff;
  font-weight: bold;
}
.header__topnav__link svg {
  display: block;
}
.header__topnav__link:hover, .header__topnav__link:focus, .header__topnav__link:active {
  color: #51b2e8;
}
.header__topnav__link:hover svg path, .header__topnav__link:focus svg path, .header__topnav__link:active svg path {
  fill: #51b2e8;
}
.header__topnav__cart {
  font-weight: normal;
  text-decoration: none;
}
.header__topnav__cart__label {
  display: none;
}
@media screen and (min-width: 35em) {
  .header__topnav__cart {
    /* 560px */
  }
  .header__topnav__cart__label {
    display: inline;
  }
}
@media screen and (min-width: 35em) {
  .header__topnav__button-list {
    /* 560px */
    display: flex;
    gap: 8px;
  }
}
.header__topnav__btn:hover, .header__topnav__btn:focus, .header__topnav__btn:active {
  background-color: #51b2e8;
  border-color: #51b2e8;
}
.header .primary-nav {
  clear: right;
  float: right;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 62em) {
  /* 992px */
  .scrolled #header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .scrolled #wrap {
    margin-top: 145px;
  }
}
.section--quick-links {
  background: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.section--quick-links .two-part-heading {
  margin-bottom: 24px;
}
@media screen and (min-width: 48em) {
  .section--quick-links .two-part-heading {
    /* 768px */
    margin-bottom: 64px;
  }
}
.section--quick-links .two-part-heading span {
  position: relative;
  display: inline-block;
  padding-bottom: 24px;
}
@media screen and (min-width: 48em) {
  .section--quick-links .two-part-heading span {
    /* 768px */
    padding-bottom: 32px;
  }
}
.section--quick-links .two-part-heading span:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: #1e5792;
}

.section--subscribe {
  background: #4a4949;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.section--subscribe a {
  color: inherit;
}
.section--subscribe a:hover, .section--subscribe a:focus, .section--subscribe a:active {
  color: inherit;
  opacity: 0.75;
}
.section--subscribe .two-part-heading__part-2 span {
  position: relative;
  display: inline-block;
}
.section--subscribe .two-part-heading__part-2 span:after {
  content: " ";
  position: absolute;
  top: -36px;
  left: 0;
  right: 0;
  height: 6px;
  background: #DADADA;
}
@media screen and (min-width: 22.5em) {
  .section--subscribe .two-part-heading__part-2 span:after {
    /* 360px */
    top: -50px;
  }
}
@media screen and (min-width: 22.5em) {
  .section--subscribe .two-part-heading__part-2 span:after {
    /* 360px */
    top: -59px;
  }
}
@media screen and (min-width: 48em) {
  .section--subscribe .two-part-heading__part-2 span:after {
    /* 768px */
    top: -89px;
  }
}

.subscribe {
  /* BP small */
  /* BP xlarge */
}
.subscribe__enews__heading {
  text-transform: uppercase;
  line-height: 1.3;
}
.subscribe__enews .button {
  width: 50%;
  min-width: 45px;
  max-width: 145px;
  gap: 8px;
}
.subscribe__socials {
  margin: 32px 0 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.subscribe__socials__heading {
  text-transform: uppercase;
  line-height: 1.3;
  flex: 1 0 auto;
  margin: 0;
}
@media screen and (min-width: 48em) {
  .subscribe {
    /* 768px */
  }
  .subscribe__header {
    display: grid;
    grid-template-columns: 25% auto;
    align-items: end;
    align-items: last baseline;
  }
  .subscribe__enews {
    width: 100%;
  }
  .subscribe__enews__form {
    flex: 1 1 auto;
    gap: 0.5%;
  }
  .subscribe__enews__form .button {
    width: 44px;
  }
}
@media screen and (min-width: 75em) {
  .subscribe {
    /* 1200px */
  }
  .subscribe__header {
    grid-template-columns: 23% auto;
  }
}
@media screen and (min-width: 92em) {
  .subscribe {
    /* 1472px */
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 32px;
  }
  .subscribe__header {
    grid-area: 1/span 11;
  }
  .subscribe__enews {
    grid-column: 1/span 9;
  }
  .subscribe__socials {
    margin-top: 0;
    grid-column: 10/span 3;
  }
  .subscribe__socials .social-list {
    margin: 0;
  }
  .subscribe__header {
    grid-template-columns: 18% auto;
  }
}

.acknowledge-of-country {
  padding-top: 32px;
  margin-top: 16px;
  border-top: 1px solid #ffffff;
  color: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  grid-column-start: 1;
  grid-column-end: 13;
}

.section--contact-us {
  background: #DADADA;
}
.section--contact-us .section {
  padding-left: 10px;
  padding-right: 10px;
}

.contact-us {
  /* BP small */
  /* BP xlarge */
}
.contact-us__heading {
  color: #51b2e8;
  font-size: 2.4rem;
}
.contact-us__map {
  background: url(../images/img_location.png) top 20% center no-repeat;
  background-size: cover;
  padding-top: 60%;
  display: none;
}
@supports (display: grid) {
  .contact-us__map {
    display: block;
  }
}
@media screen and (min-width: 48em) {
  .contact-us {
    /* 768px */
  }
  .contact-us__details__text-cols {
    display: flex;
    flex-flow: row nowrap;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
  }
  .contact-us__details__text-cols p {
    border-right: 1px solid #7f7f7f;
    padding: 0 16px;
  }
  @supports (display: grid) {
    .contact-us__details__text-cols p {
      padding: 0;
    }
  }
  .contact-us__details__text-cols p:last-child {
    border-right: 0;
  }
}
@media screen and (min-width: 75em) {
  .contact-us {
    /* 1200px */
    display: flex;
    flex-flow: row nowrap;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 32px;
  }
}
@media screen and (min-width: 92em) {
  .contact-us {
    /* 1472px */
    grid-template-columns: 364px auto;
  }
  .contact-us__details__text-cols {
    grid-gap: 32px;
  }
}

.terms-footer {
  font-size: 1.5rem;
  /* BP small */
  /* BP medium */
}
.terms-footer-sm-links {
  display: block;
  margin-top: 16px;
}
.terms-footer-sm-links a {
  text-decoration-color: transparent;
}
.terms-footer-sm-links a:hover,
.terms-footer-sm-links a:focus,
.terms-footer-sm-links a:active {
  text-decoration-color: inherit;
}
.terms-footer__memberships {
  font-family: "roboto-slab", serif;
}
.terms-footer__memberships__logo {
  max-height: 60px;
  width: auto;
}
.terms-footer__memberships__logo--asta {
  margin-left: 20px;
}
@media screen and (min-width: 48em) {
  .terms-footer {
    /* 768px */
  }
  .terms-footer__memberships {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  .terms-footer__memberships__logo {
    margin-left: 32px;
  }
}
@media screen and (min-width: 62em) {
  .terms-footer {
    /* 992px */
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 16px;
    align-items: center;
  }
  .terms-footer p {
    margin: 0;
  }
}
@media screen and (min-width: 92em) {
  .terms-footer {
    /* 1472px */
  }
  .terms-footer-sm-links {
    display: inline;
    margin-top: 0;
  }
}

.section--terms-footer {
  background: #1e5792;
  color: #ffffff;
  padding-left: 1em;
  padding-right: 1em;
}
.section--terms-footer a {
  color: inherit;
}
.section--terms-footer a:hover, .section--terms-footer a:focus, .section--terms-footer a:active {
  color: inherit;
  opacity: 0.75;
}

@media screen and (min-width: 22.5em) {
  /* 360px */
  .section--quick-links {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .section--subscribe {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .section--contact-us .section {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  .section--terms-footer {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  /* 768px */
  .section--quick-links {
    padding-left: 3em;
    padding-right: 3em;
  }

  .section--subscribe {
    padding-left: 3em;
    padding-right: 3em;
  }

  .section--contact-us .section {
    padding-left: 3em;
    padding-right: 3em;
  }

  .section--terms-footer {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media screen and (min-width: 75em) {
  /* 1200px */
  .section--quick-links {
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 6em;
  }

  .section--subscribe {
    padding-left: 5em;
    padding-right: 5em;
  }

  .section--contact-us .section {
    padding-right: 5em;
    padding-left: 5em;
  }
  @supports (display: grid) {
    .section--contact-us .section {
      padding-left: 0;
    }
  }

  .section--terms-footer {
    padding-left: 5em;
    padding-right: 5em;
  }
}
.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tile-list:before, .tile-list:after {
  content: " ";
  display: table;
}
.tile-list:after {
  clear: both;
}
.tile-list__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /*  768px */
  .tile-list {
    margin: 0 -30px;
  }
  .tile-list__item {
    width: 33.333%;
    padding: 15px 30px;
    float: left;
  }
}
/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  /* 560px */
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 87.5em) {
  /* 1400px */
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    /* 560px */
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 991px) {
    /* 991px */
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    /* 992px */
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object-list:before, .media-object-list:after {
  content: " ";
  display: table;
}
.media-object-list:after {
  clear: both;
}

.media-object-list__item {
  padding: 8px 0;
  border-top: 2px solid #DADADA;
  /* BP small */
  /* BP large */
}
@media screen and (min-width: 48em) {
  .media-object-list__item {
    /* 768px */
    padding: 20px 0;
  }
}
@media screen and (min-width: 75em) {
  .media-object-list__item {
    /* 1200px */
    padding: 28px 0;
  }
}

.media-object-list__item:first-child {
  border-top: 0px;
}

.media-object-list:after {
  clear: both;
}

.card-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media screen and (min-width: 48em) {
  .card-list {
    /* 768px */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
.card-list__item {
  margin-top: 16px;
  width: 33.33%;
}

@supports (display: grid) {
  .card-list {
    display: grid;
    grid-gap: 32px;
    /* BP small */
    /* BP xlarge */
  }
  @media screen and (min-width: 48em) {
    .card-list {
      /* 768px */
      grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (min-width: 92em) {
    .card-list {
      /* 1472px */
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .card-list__item {
    margin-top: 0;
    width: auto;
  }

  .card-list--events {
    /* BP small */
  }
  @media screen and (min-width: 48em) {
    .card-list--events {
      /* 768px */
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .card-list--events .card-list__item {
      grid-column: span 2;
    }
  }
  @media screen and (min-width: 92em) {
    .card-list--events {
      /* 1472px */
      grid-template-columns: 1fr 1fr 1fr;
    }
    .card-list--events .card-list__item {
      grid-column: span 1;
    }
    .card-list--events.card-list--single-row .card-list__item:nth-child(n+4) {
      display: none;
    }
  }
}
.two-col-section-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.two-col-section-header :where(.button) {
  text-transform: uppercase;
}

@media screen and (min-width: 92em) {
  .card-list--membership {
    /* 1472px */
    grid-template-columns: repeat(5, 1fr);
    gap: 32px;
  }
}
@media screen and (min-width: 48em) and (max-width: 91.9375em) {
  .card-list--membership {
    /* 768px - 1471px */
  }
  .card-list--membership .card-list__item:last-child:nth-child(5) {
    grid-column: 1/-1;
  }
}
.card-list--membership .card__img__icon {
  background: no-repeat center center;
  background-size: contain;
}
.card-list--membership .card-list__item:nth-child(1) .card__img__icon {
  background-image: url(../images/icon-system/icon_personal.svg);
}
.card-list--membership .card-list__item:nth-child(2) .card__img__icon {
  background-image: url(../images/icon-system/icon_early-career.svg);
}
.card-list--membership .card-list__item:nth-child(3) .card__img__icon {
  background-image: url(../images/icon-system/icon_school.svg);
}
.card-list--membership .card-list__item:nth-child(4) .card__img__icon {
  background-image: url(../images/icon-system/icon_corporate.svg);
}
.card-list--membership .card-list__item:nth-child(5) .card__img__icon {
  background-image: url(../images/icon-system/icon_total.svg);
}

.section--countdown {
  background-color: #51b2e8;
  color: #ffffff;
}

.countdown {
  display: grid;
  gap: 16px;
  text-align: center;
}
@media screen and (min-width: 62em) {
  .countdown {
    /* 992px */
    grid-template-columns: 1.5fr 1.5fr;
    text-align: left;
  }
}
.countdown__header {
  padding-left: 1em;
  padding-right: 1em;
}
@media screen and (min-width: 22.5em) {
  .countdown__header {
    /* 360px */
    padding-left: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .countdown__header {
    /* 768px */
    padding-left: 3em;
  }
}
@media screen and (min-width: 62em) {
  .countdown__header {
    /* 992px */
    display: flex;
    flex-flow: column nowrap;
    gap: 14px;
  }
}
@media screen and (min-width: 75em) {
  .countdown__header {
    /* 1200px */
    padding-left: 5em;
  }
}
.countdown__header :where(*):last-child {
  margin-bottom: 0;
}
.countdown__title {
  color: #ffffff;
  text-wrap: balance;
}
@media screen and (min-width: 48em) {
  .countdown__title {
    /* 768px */
    font-size: 3.2rem;
    line-height: 1.12;
  }
}
@media screen and (min-width: 62em) {
  .countdown__details {
    /* 992px */
    order: -1;
    margin-bottom: 2px;
  }
}
@media screen and (min-width: 92em) {
  .countdown__details {
    /* 1472px */
    font-size: 1.12em;
  }
}
.countdown__details__item {
  display: inline-block;
}
.countdown__cta {
  min-width: 20%;
}

.countdown-numbers {
  margin-block: 0;
  margin-inline: auto;
  width: 100%;
  padding-left: 1em;
}
@media screen and (min-width: 22.5em) {
  .countdown-numbers {
    /* 360px */
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .countdown-numbers {
    /* 768px */
    padding-right: 3em;
  }
}
@media screen and (min-width: 75em) {
  .countdown-numbers {
    /* 1200px */
    padding-right: 5em;
  }
}

.countdown-increment {
  display: grid;
  align-content: center;
}
.countdown-increment__value {
  font-weight: bold;
}

/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */
/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */
.product-list {
  list-style-type: none;
  padding: 0;
  margin: -20px -20px 0;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-list__item {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  text-align: center;
  float: left;
  border: 1px solid #ccc;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}

.product-list__item > * {
  -ms-flex: 1 1 100%;
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  max-width: 100%;
}

.product-list__item__out-stock {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border: 2px solid #a8a8a8;
  opacity: 0.66;
  outline: none;
  cursor: not-allowed;
  text-transform: lowercase;
  font-size: 1.6rem;
  line-height: 1;
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: bold;
}
@media screen and (min-width: 48em) {
  .product-list__item__out-stock {
    padding: 8px 12px 8px;
  }
}

.product-list__item__title {
  font-size: 1.6rem;
  margin: 0;
  margin-bottom: 0;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  line-height: 1.2;
  width: 100%;
  display: block;
  font-weight: bold;
}

.product-list__item__title a {
  text-decoration: none;
  color: inherit;
}

.js--add-to-event {
  cursor: pointer;
}

.product-list__item__price {
  margin-bottom: 7px;
}

.product-list__item__thumb a {
  display: block;
}

.product-list__item__thumb__img {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}

.product-list__item__buttons p {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 0;
}

.product-list__item__buttons p > * {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 7px;
  margin-bottom: 0;
}

/* ---- Product list ---- */
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .product-list__item {
    width: calc(50% - 40px);
  }

  .product-list__item__content {
    margin-top: auto;
  }
}
/* BP medium */
@media screen and (min-width: 62em) {
  /* 992px */
  .product-list__item {
    width: calc(50% - 40px);
  }
}
.product-detail {
  display: grid;
  gap: 8px;
}
@media screen and (min-width: 35em) {
  .product-detail {
    gap: 24px;
    grid-template-columns: 1fr 2fr;
  }
}
@media screen and (min-width: 75em) {
  .product-detail {
    grid-template-columns: 1fr 2.25fr;
    gap: 40px;
  }
}
.product-detail__name {
  text-wrap: balance;
  line-height: 1.25;
}
.product-detail__price {
  font-size: 1.5em;
}

.product .qty input.textbox {
  max-width: 76px;
  display: inline;
}

.btn-remove {
  background: url(../images/icon-system/icon_form_cross-red.svg) no-repeat center center;
  background-size: 26px auto;
  width: 32px;
  height: 32px;
  margin-top: 1px;
  text-indent: -9999px;
  display: inline-block;
  opacity: 0.7;
  filter: grayscale(1);
  vertical-align: middle;
}

.btn-remove:hover,
.btn-remove:active {
  opacity: 1;
  filter: grayscale(0);
}

/* Cart buttons */
.cart-buttons {
  padding: 12px;
  margin: 30px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .cart-buttons {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.forward-link {
  margin-right: 5px;
}

/* BP 3 */
@media screen and (max-width: 600px) {
  .cart-buttons .button:last-child {
    margin-bottom: 0;
  }

  .cart-buttons {
    margin: 15px 0;
  }
}
.checkout-table .total {
  text-align: right;
}

/* Infoboxes */
.checkout-infobox {
  padding: 10px;
  margin: 16px 0;
  font-size: 1.4rem;
  color: #FFF;
  background-color: #26883A;
  border-left: 4px solid #0E6F22;
}

.checkout-errorbox {
  background-image: none !important;
  background-color: #C32C2A !important;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}

.checkout-infobox a {
  color: inherit;
}

.checkout-infobox p {
  margin: 0 0 3px;
}

.checkout-infobox p:last-child {
  margin-bottom: 0;
}

.popup-message {
  background-color: #D2DDE9;
  margin: 0 0 15px 0;
  padding: 8px 10px;
  opacity: 0;
  color: #1E5792;
}

.popup-message:empty {
  display: none;
}

/* Shipping methods table */
#shipping-methods table {
  border-top: 1px #e7e7e7 dashed;
  border-bottom: 1px #e7e7e7 dashed;
  width: 100%;
  margin-bottom: 20px;
}

#shipping-methods table td.name {
  font-size: 14px;
  width: 320px;
}

#shipping-methods table td.info {
  font-size: 10px;
  width: 300px;
  padding: 5px 0;
}

#shipping-methods table td.total {
  font-size: 14px;
  text-align: right;
  color: #333;
}

/* Payment module stuff */
#payment-listing {
  margin-bottom: 20px;
}

table.payment-methods {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
}

table.payment-methods td.radio {
  padding: 10px;
  border-bottom: 1px #ccc dashed;
  width: 35px;
}

table.payment-methods td.name {
  padding: 10px 0;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px #ccc dashed;
}

table.payment-methods td.desc {
  padding: 8px 0 15px 0;
}

div.payment-continue {
  text-align: right;
}

input.cc-cardname {
  width: 200px;
}

input.cc-cardnum {
  width: 200px;
}

select.cc-type {
  width: 120px;
}

select.cc-exp {
  width: 80px;
}

input.cc-cvn {
  width: 60px;
}

/* Confirmation */
table#payment-confirmation {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

table#payment-confirmation th,
table#payment-confirmation td {
  border-bottom: 1px #ccc dashed;
  padding: 8px 12px;
  font-size: 14px;
  text-align: left;
}

table#payment-confirmation th {
  font-weight: bold;
  width: 200px;
}

@media print {
  #payment-steps {
    display: none;
  }

  div.checkout-infobox {
    display: none;
  }

  div.cart-buttons {
    display: none;
  }
}
/* SHOPPING CART */
.widget-ProductList table {
  width: 100%;
}

.cart-add-form {
  display: grid;
  gap: 16px;
}
@media screen and (min-width: 35em) {
  .cart-add-form {
    grid-template-columns: 1fr 1.75fr;
    gap: 24px;
  }
}
.cart-add-form__image {
  grid-row: 1/-1;
  display: grid;
  justify-content: center;
}
.cart-add-form__product-name {
  margin-block: 0 8px;
  line-height: 1.25;
  text-wrap: balance;
}
.cart-add-form__price {
  font-size: 1.25em;
}
.cart-add-form__details {
  display: flex;
  flex-flow: column nowrap;
}
.cart-add-form__quantity__input {
  min-width: 45%;
  text-align: center;
  margin-top: 2px;
}
.cart-add-form__btn-bar {
  display: flex;
  gap: 8px;
  grid-column: 2/-1;
  margin-top: auto;
}
.cart-add-form :where(.button) {
  padding-block: 12px;
}
.cart-add-form__add-t-cart {
  flex: 1 1 auto;
}

/* ---- Blog hub ---- */
.blog-post-preview {
  margin: 0 0 20px;
}

.blog-post-preview__inner {
  border: 1px solid #dedede;
  padding: 20px 20px 16px;
  -webkit-transition: background 300ms ease;
  -o-transition: background 300ms ease;
  transition: background 300ms ease;
  height: 100%;
  overflow: hidden;
}

.blog-post-preview .blog-post-featured-img-small {
  width: 100%;
}

.blog-post-preview__inner:hover {
  background: #f8f8f8;
}

.blog-post-preview__inner:hover .view-more-link {
  opacity: 0.9;
}

.blog-post-preview .view-more-link {
  padding: 20px;
  border: 1px solid #dedede;
}

.blog-post-preview div.side {
  float: left;
  width: 19.7%;
  border-right: 1px solid #dedede;
  text-align: center;
  margin-top: 30px;
}

.blog-post-preview .blog-date {
  border-bottom: 1px solid #dedede;
  float: right;
  padding: 0 11% 0 0;
  margin-bottom: 20px;
}

.blog-post-preview div.share {
  padding-right: 14%;
  margin-top: 10px;
}

.blog-post-preview div.hub-share p {
  color: #909396;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  display: inline;
}

.blog-post-preview div.hub-share a img {
  margin: 0 0 0 15px;
  display: inline;
}

div.share a:hover {
  text-decoration: none;
}

div.share a:hover img {
  zoom: 1;
  opacity: 0.5;
  -webkit-transition: opacity 300ms ease-in;
  -o-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

.blog-post-preview a {
  text-decoration: none;
}

.blog-post-preview div.excerpt {
  margin-top: 20px;
}

.blog-post-preview div.excerpt h3 {
  font-weight: 300;
  font-size: 2.1rem;
  text-align: left;
  padding-bottom: 0;
  margin: 0;
}

.blog-post-preview div.excerpt h3:before,
.blog-post-preview div.excerpt h3:after {
  display: none;
}

.blog-post-preview div.excerpt p.author {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.blog-post-preview div.excerpt p {
  line-height: 140%;
  font-size: 1.7rem;
  margin: 12px 0 0;
}

.bloghub-posts--unfiltered .blog-post-preview--hidden,
.bloghub-posts--filtered .blog-post-preview--filtered-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.bloghub-posts--unfiltered .blog-post-preview--animate {
  -webkit-animation: fadeInDown 300ms ease;
  animation: fadeInDown 300ms ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.bloghub-posts--filtered .blog-post-preview--filtered-pre-visible {
  opacity: 0;
}

.bloghub-posts--unfiltered .blog-post-preview--animate-grow,
.bloghub-posts--filtered .blog-post-preview--filtered-visible {
  -webkit-animation: fadeInDown 350ms ease;
  animation: fadeInDown 350ms ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.blog-post-preview--image-loading .blog-post-featured-img-small {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.blog-post-preview .blog-post-img-link {
  position: relative;
  overflow: hidden;
  padding-top: 61.67%;
  display: block;
}

.blog-post-preview .blog-post-featured-img-small,
.blog-post-preview .blog-post-featured-img-small-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.blog-post-preview .blog-post-featured-img-small-placeholder {
  -webkit-filter: blur(6px);
  filter: blur(6px);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.blog-post-preview.blog-post-preview--image-loaded .blog-post-featured-img-small-placeholder {
  opacity: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  transition-duration: 1.5s;
}

.bloghub-filters {
  display: none;
}

.bloghub-filters.bloghub-filters--visible {
  display: block;
}

.bloghub-filters-list .button:focus {
  outline: none;
}

/* BP Small */
@media only screen and (min-width: 48.063em) {
  /* 769px */
  .bloghub-filters {
    display: block;
  }

  .bloghub-show-filters-button-wrap {
    display: none;
  }

  #bloghub-posts {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1.5%;
    margin-right: -1.5%;
  }

  .blog-post-preview {
    padding: 0 1.5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    margin: 0 0 35px;
  }

  .blog-post-preview__inner {
    padding: 30px;
  }

  .blog-post-preview div.excerpt h3 {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 1000px) {
  .blog-post-preview {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33%;
  }

  .blog-post-preview div.excerpt h3 {
    font-size: 2.5rem;
  }
}
/* ---- Blog list items ---- */
.blog-item {
  margin-bottom: 24px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e4e5e5;
}

.details {
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
}

.details .author {
  padding-right: 6px;
}

/* ---- Blog Posts ---- */
.blog-posts {
  margin: 30px 0;
}

.blog-post-featured-img {
  margin-bottom: 16px;
}

.blog-post {
  border-bottom: 2px solid #e4e5e5;
  padding-bottom: 5px;
  margin-bottom: 25px;
}

.blog-post__details {
  display: block;
  font-size: 1.6rem;
  text-transform: uppercase;
  line-height: 1.6;
  display: flex;
  flex-flow: row nowrap;
}

.blog-post__details .author {
  padding-right: 6px;
}

.blog-post__img {
  margin-bottom: 15px;
}

.blog-posts__footer-link {
  margin: -10px 0 0;
  padding-bottom: 15px;
  border-bottom: 2px solid #e4e5e5;
}

.widget-BlogIntro.orientation-tall .blog-post__img {
  display: none;
}

.blog-post__body {
  padding-bottom: 40px;
}

.blog-post__tags {
  border-top: 2px solid #e4e5e5;
  clear: both;
  padding: 10px 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.blog-post__tags h4 {
  padding-right: 16px;
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

.blog-post__tags .button {
  margin: 8px;
}

.blog-post__footer {
  border-top: 2px solid #e4e5e5;
  padding: 12px 0 8px;
}

.blog-post__footer .share {
  text-transform: uppercase;
}

.blog-post__footer .share p {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
}

.blog-post__footer .share a {
  display: inline-block;
  text-decoration: none;
  margin-left: 12px;
  display: flex;
}

.blog-post__footer .share a.share-btn--facebook {
  margin-left: 4px;
}

@media screen and (min-width: 37.5em) {
  /*  600px */
  .blog-post__footer .share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .blog-post__footer .share:last-child {
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .blog-post__img {
    width: 28%;
    float: left;
  }

  .blog-post__content {
    float: right;
    width: 68%;
  }

  .widget-BlogIntro.orientation-tall .blog-post__content {
    width: 66%;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
  }

  .widget-BlogIntro.orientation-tall .blog-post__img {
    display: block;
    max-width: 100px;
  }
}
@media screen and (min-width: 1000px) {
  .blog-post__img {
    width: 28%;
    float: left;
  }

  .blog-post__content {
    float: right;
    width: 68%;
  }

  .widget-BlogIntro.orientation-tall .blog-post__content {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .widget-BlogIntro.orientation-tall .blog-post__content {
    float: right;
    width: 66%;
  }

  .widget-BlogIntro.orientation-tall .blog-post__img {
    display: block;
    max-width: 100px;
  }
}
/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */
.home-banner {
  background: #ffffff;
  /* BP large */
}
@media screen and (min-width: 75em) {
  .home-banner {
    /* 1200px */
    display: grid;
    grid-template-columns: 64% auto;
  }
  .home-banner:before, .home-banner:after {
    content: " ";
    display: table;
  }
  .home-banner:after {
    clear: both;
  }
  @supports (display: grid) {
    .home-banner:before {
      display: none;
    }
  }
  .home-banner__simple-cards {
    padding-right: 16px;
  }
}
.home-banner .promos {
  position: relative;
  padding-bottom: 8px;
  /* BP large */
}
@media screen and (min-width: 75em) {
  .home-banner .promos {
    /* 1200px */
    max-width: 64%;
    float: left;
  }
  @supports (display: grid) {
    .home-banner .promos {
      max-width: none;
    }
  }
}
.home-banner .promos__simple-cards {
  margin-top: -8%;
  position: relative;
  /* BP small */
}
@media screen and (min-width: 48em) {
  .home-banner .promos__simple-cards {
    /* 768px */
    margin-top: -10%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 75em) {
  .home-banner .promos__simple-cards {
    /* 1200px */
    padding-right: 8px;
  }
}
@media screen and (min-width: 75em) and (max-width: 91.9375em) {
  .home-banner .promos__simple-cards {
    /* 1200px - 1471px */
    margin-top: -12%;
  }
  .home-banner .promos__simple-cards .card__heading {
    font-size: 2rem;
  }
}
.home-banner .promos__simple-cards__item {
  padding: 0 16px;
}
@media screen and (min-width: 48em) {
  .home-banner .promos__simple-cards__item {
    /* 768px */
    float: left;
    width: 50%;
  }
  .home-banner .promos__simple-cards__item .card__img,
.home-banner .promos__simple-cards__item .card__img img {
    max-height: 206px;
  }
  @supports (display: flex) {
    .home-banner .promos__simple-cards__item {
      width: auto;
    }
    .home-banner .promos__simple-cards__item .card__img,
.home-banner .promos__simple-cards__item .card__img img {
      max-height: none;
    }
  }
}
.home-banner .promos:before, .home-banner .promos:after {
  content: " ";
  position: absolute;
  bottom: 0;
  width: 50%;
  height: 8px;
}
.home-banner .promos:before {
  background: #534bbd;
  left: 0;
}
.home-banner .promos:after {
  background: #53af48;
  right: 0;
}
@media screen and (min-width: 75em) {
  .home-banner .promos {
    /* 1200px */
  }
  .home-banner .promos:after {
    background: #53af48;
    right: 0;
    width: calc(50% + 4px);
  }
}

.hm-blog-feed {
  padding: 2em 1em;
  border-bottom: 8px solid #DADADA;
  margin-bottom: 16px;
  display: flex;
  flex-flow: column nowrap;
}
.hm-blog-feed__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.hm-blog-feed__header__btns {
  text-transform: uppercase;
  text-align: right;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.hm-blog-feed__header .two-part-heading {
  position: relative;
  top: 8px;
}
@media screen and (min-width: 48em) {
  .hm-blog-feed__header .two-part-heading {
    /* 768px */
    padding-top: 8px;
  }
}
.hm-blog-feed .media-object-list {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.hm-blog-feed .media-object-list:before {
  content: none;
}
@media screen and (min-width: 75em) and (max-width: 91.9375em) {
  .hm-blog-feed .media-object__heading {
    /* 1200px - 1471px */
    font-size: 2rem;
  }
}
.hm-blog-feed__footer {
  padding-top: 2em;
  border-top: 2px solid #DADADA;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media screen and (min-width: 75em) {
  .hm-blog-feed {
    /* 1200px */
    padding-left: 32px;
    padding-right: 5em;
    border-left: 1px solid #DADADA;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 92em) {
  .hm-blog-feed {
    /* 1472px */
    padding-left: 3em;
  }
}

.section--events {
  padding-left: 1em;
  padding-right: 1em;
  border-bottom: 8px solid #DADADA;
  margin-bottom: 16px;
}
@media screen and (min-width: 22.5em) {
  .section--events {
    /* 360px */
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .section--events {
    /* 768px */
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 75em) {
  .section--events {
    /* 1200px */
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 6em;
    margin-bottom: 48px;
  }
}
.section--events__header {
  margin-bottom: 16px;
}
@media screen and (min-width: 48em) {
  .section--events__header {
    /* 768px */
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
.section--events .two-part-heading {
  margin-bottom: 24px;
}
@media screen and (min-width: 48em) {
  .section--events .two-part-heading {
    /* 768px */
    margin-bottom: 64px;
  }
}
.section--events .two-part-heading span {
  position: relative;
  display: inline-block;
  padding-bottom: 24px;
}
@media screen and (min-width: 48em) {
  .section--events .two-part-heading span {
    /* 768px */
    padding-bottom: 32px;
  }
}
.section--events .two-part-heading span:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: #1e5792;
}

.section--stats,
.section--quick-links {
  padding-left: 1em;
  padding-right: 1em;
  border-bottom: 8px solid #DADADA;
  margin-bottom: 16px;
}
@media screen and (min-width: 22.5em) {
  .section--stats,
.section--quick-links {
    /* 360px */
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .section--stats,
.section--quick-links {
    /* 768px */
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 75em) {
  .section--stats,
.section--quick-links {
    /* 1200px */
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 6em;
    margin-bottom: 48px;
  }
}

.content {
  background: #ffffff;
  padding-top: 32px;
  padding-left: 1em;
  padding-right: 1em;
  border-bottom: 8px solid #DADADA;
  margin-bottom: 16px;
  /* BP xlarge */
}
@media screen and (min-width: 22.5em) {
  .content {
    /* 360px */
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media screen and (min-width: 48em) {
  .content {
    /* 768px */
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 75em) {
  .content {
    /* 1200px */
    padding-top: 48px;
    padding-left: 5em;
    padding-right: 5em;
    padding-bottom: 6em;
    margin-bottom: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr calc(25.4% - 2.5em);
    /* matches inner banner */
    grid-gap: 40px;
  }
  .content .sidebar {
    min-width: calc(25.4% - .5em);
    padding-left: 2em;
  }
  @supports (display: grid) {
    .content .sidebar {
      padding-left: 0;
    }
  }
}
@media screen and (min-width: 92em) {
  .content {
    /* 1472px */
    grid-gap: 96px;
  }
}

@media screen and (min-width: 75em) {
  .wide .content {
    /* 1200px */
    grid-template-columns: 100%;
  }
}

.mainbar {
  margin-bottom: 40px;
}
@media screen and (min-width: 75em) {
  .mainbar {
    /* 1200px */
    margin-bottom: 0;
  }
}

/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /* Don't show links for images, or javascript/internal links */
  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}