
/* ---- Flex ---- */
.field-elements-flex {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex: 1 1 100%;
    -webkit-box-flex: 1;
    flex: 1 1 100%;
	max-width: calc(100% + 4px);
	margin-left: -2px;
	margin-right: -2px;

    &:before,
    &:after {
        content: none;
    }

    .field-element {
        float: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: $spacing;
        padding-left: 2px;
        padding-right: 2px;

        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: 100%;
    }

    .field-element--checkboxset {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media only screen and (min-width: 35em) { /* 560px */
	.field-elements-flex .field-element {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		max-width: 100%;
	}

    .field-elements-flex button[type="submit"],
    .field-elements-flex .field-element--button {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
    }
}

@media only screen and (min-width: 48em) { /* 768px */
	.field-elements-flex {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
    }

    .field-elements-flex .field-element,
    .field-elements-flex .field-element--grow {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		max-width: 100%;
	}

    .field-elements-flex button[type="submit"],
    .field-elements-flex .field-element--button,
    .field-elements-flex .field-element--shrink {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
    }
}

.button-flex-field {
    height: 44px;
    width: 44px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button-flex-field svg path {
    fill: #fff;
}
