
.section--countdown {
    background-color: $color-primary;
    color: $color-white;
}

.countdown {
    display: grid;
    gap: $spacing*2;
    text-align: center;


    @media screen and (min-width: 62em) { /* 992px */
        grid-template-columns: 1.5fr 1.5fr;
        text-align: left;
    }

    &__header {
        padding-left: 1em;
        padding-right: 1em;


        @media screen and (min-width: 22.5em) {/* 360px */
            padding-left: 1.5em;
        }

        @media screen and (min-width: 48em) { /* 768px */
            padding-left: 3em;
        }

        @media screen and (min-width: 62em) { /* 992px */
            display: flex;
            flex-flow: column nowrap;
            gap: $spacing*1.75;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-left: 5em;
        }

        :where(*):last-child {
            margin-bottom: 0;
        }

    }

    &__title {
        color: $color-white;
        text-wrap: balance;

        @media screen and (min-width: 48em) { /* 768px */
            font-size: 3.2rem;
            line-height: 1.12;
        }
    }

    &__details {

        @media screen and (min-width: 62em) { /* 992px */
            order: -1;
            margin-bottom: $spacing*.25;
        }
        @media screen and (min-width: 92em) {/* 1472px */
            font-size: 1.12em;
        }

        &__item {
            display: inline-block;
        }
    }

    &__cta {
        min-width: 20%;
    }

}

.countdown-numbers {
    margin-block: 0;
    margin-inline: auto;
    width: 100%;

    padding-left: 1em;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-right: 1.5em;
    }

    @media screen and (min-width: 48em) { /* 768px */
        padding-right: 3em;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-right: 5em;
    }
}

.countdown-increment {
    display: grid;
    align-content: center;

    &__value {
        font-weight: bold;
    }
}
