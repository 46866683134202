

// Brand colours

$color-primary: 					#51b2e8; // Blue

$color-secondary:                   #1e5792; // NAVY

$color-secondary--hover: 			darken($color-secondary, 40%);

$color-alternative:                 #53af48; // Green

$color-accent-01: 				    #FAA41A; // Yellow

$color-accent-02:                   rgb(239,55,62); // red


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #F0F0F0;

$color-grey-02:						#DADADA;

$color-grey-03:						#b8b8b8;

$color-grey-04:						#9c9c9c;

$color-grey-05:						#7f7f7f;

$color-grey-06:						#565555;

$color-grey-07:						#4a4949;

$color-grey-08:						#403f3f;

$color-grey-09:						#2f2e2e;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#0E6F22;

$color-utility-positive-subtle:		#26883A;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				$color-primary;

$color-utility-neutral-subtle:		#d3f2ff;
