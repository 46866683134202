
.btn-filter {
    text-transform: uppercase;
    font-family: $primary-font;

    &__title {
        font-family: $primary-font;
        font-size: 1.6rem;
        font-weight: 100;
        margin: 0 $spacing 0 0;
        white-space: nowrap;



    }

    &__list {
        list-style-type: none;
        margin: -2px;
        padding: 0;
        display: flex;
        flex-flow: row wrap;

        li {
            display:block;
            padding-right: 0;
            margin: 2px;
        }

        &__btn {
            font-weight: 100;
            background-color: transparent;
            border: 2px solid $color-grey-02;
            text-transform: uppercase;
            color: $color-grey-05;
            line-height: 1;
            padding: $spacing*1.5;
            padding-top: $spacing - 2px;
            padding-bottom: $spacing - 2px;

            &:hover,
            &:focus,
            &:active,
            &--on {
                background: $color-primary;
                border-color: $color-primary;
                color: $color-white;
            }
        }
    }
}
