
.section--nav {
    background: $color-secondary;
    padding: 0;
}


/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/


/** Desktop and Mobile styling
===================================*/
.extra-mobile-menu-items,
#frankenmenu-list,
#frankenmenu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

.extra-mobile-menu-items a,
#frankenmenu-list a {
    text-decoration: none;
    display: block;
    font-size: 1.6rem;
}


/** Desktop menu only
===================================*/
@media screen and (min-width: 992px) {

    .extra-mobile-menu-items {
        display: none;
    }

    .primary-nav {
        width: 100%;
        margin-top: $spacing*2;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    #frankenmenu {
        z-index: 1000;
    }

    #frankenmenu-list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: calc(100% + 16px);
        margin-inline: -8px;
    }
    #mobile-header {
        display: none;
    }

    /* ---- All level menu items ---- */
    #frankenmenu-list .menu-item {
        position: relative;
    }
    #frankenmenu-list .sub-menu {
        display: none;
        min-width: 200px;
        position: absolute;
        -webkit-box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
                box-shadow: 0 0 16px 1px rgba(0,0,0,0.4);
        z-index: 1;
        min-width: 250px;
        max-width: 350px;
        text-align: left;
        top: 0;
        left: 100%;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1 {
        text-align: center;
    }

    #frankenmenu-list .menu-item-depth1 > a {
        display: block;
        color: rgba(255,255,255,0.8);
        font-size: 1.3rem;
        padding: $spacing 0 6px;
        margin: 0 $spacing;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 2px solid rgba($color-primary, 0);
    }

    /* Hover */
    #frankenmenu-list .menu-item-depth1 > a:hover,
    #frankenmenu-list .menu-item-depth1 > a:focus,
    #frankenmenu-list .menu-item-depth1.frankenhover > a,
    #frankenmenu-list .menu-item-depth1.frankenhold > a {
        color: $color-primary;
        border-bottom: 2px solid rgba($color-primary, 100%);
    }

    /* Current item */
    #frankenmenu-list .menu-item-depth1.menu-current-item > a {
        border-color: rgba($color-white, 100%);
        color: rgba($color-white, 100%);
    }

    /* Current ancestor */
    #frankenmenu-list .menu-item-depth1.menu-current-item-ancestor > a {
        border-color: rgba(255,255,255,0.5);
        color: rgba(255,255,255,1);
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu a {
        border-top: none;
        border-left: 3px solid #fff;
        padding: 12px 10px 12px;
        color: #4a4949;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2;
        background-color: #fff;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
    }
    #frankenmenu-list .sub-menu .menu-item:first-child a {
        border-top: none;
    }
    #frankenmenu-list .sub-menu .menu-item > a:hover,
    #frankenmenu-list .sub-menu .menu-item > a:focus,
    #frankenmenu-list .sub-menu .menu-item.frankenhover > a,
    #frankenmenu-list .sub-menu .menu-item.frankenhold > a {
        background: #fff;
        color: #000;
        border-color: #51b2e8;
        padding-left: 15px;
    }

    #frankenmenu-list .frankenhold > .sub-menu {
        display: block !important;
        opacity: 1 !important;
    }

    #frankenmenu-list .sub-menu .menu-item.menu-current-item > a {
        border-color: #51b2e8;
        color: #000;
        padding-left: 15px;
    }

    /* ---- First level submenus ---- */
    #frankenmenu-list .sub-menu-depth1 {
        top: 100%;
        left: 16px;
    }

    /* ---- Right aligned submenus ---- */
    #frankenmenu-list .sub-menu.submenu-right-align,
    #frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
        left: auto;
        right: 100%;
    }
    #frankenmenu-list .sub-menu-depth1.submenu-right-align {
        left: auto;
        right: 0;
    }


    /* ---- Sub-navigation ---- */
    #frankenmenu-list .menu-item-subnav {
        display: none;
    }
    #frankenmenu-subnav {
        float: right;
    }
    #frankenmenu-subnav-list,
    #frankenmenu-subnav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #frankenmenu-subnav-list .menu-item-depth1 {
        display: inline-block;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a {
        text-decoration: none;
        padding: 5px 5px;
        display: block;
        color: inherit;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
        text-decoration: underline;
    }
    #frankenmenu-subnav-list .sub-menu {
        display: none;
    }

    /* ---- Moved elements ---- */
    .frankenmoved {
        display: none;
    }

    /* ---- Megamenu ---- */

    #frankenmenu-list .mega-menu {
        left: 0;
        right: 0;
        background-color: #fff;
        color: #4a4949;
        text-align: left;
        top: 100%;
        top: calc(100% - 32px);
        position: absolute;
        border-bottom: none;
        z-index: 51;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
    }

    /* Remove relative positioning for mega menu */
    #frankenmenu-list.mega .menu-item {
        position: static;
    }
    #frankenmenu-list .menu-item.frankenhover .mega-menu,
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        padding: 36px 0 0;
        width: 100vw;
        height: auto;
        clip: auto;
        overflow: visible;
        margin: auto;
        box-shadow: 0 36px 36px rgba(0,0,0,0.25);
    }
    .scrolled #frankenmenu-list .menu-item.frankenhover .mega-menu{
        top: calc(100% - 12px);
    }

    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        display: block !important;
        height: auto !important;
    }

    #frankenmenu-list .menu-item .mega-menu .container {
        padding: 0 0 36px;
    }

    /* Custom Mega Menu - preview row  */
    #frankenmenu-list .mega-menu-preview-row {
        margin-bottom: 8px;
    }

    /* Megamenu preview */
    #frankenmenu-list .mega-menu-preview {
        float: left;
        color: #484848;
        padding: 0;
        width: 22%;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-preview-image {
        height: 174px;
        background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
        background-size: cover;
    }

    #frankenmenu-list .mega-menu-preview-title {
        color: #484848;
        display: inline-block;
        margin-bottom: 0;
        font-size: 2.4rem;
    }
    #frankenmenu-list .mega-menu-preview-text {
        display: inline-block;
        margin-bottom: 0;
        margin-top: 0;
        line-height: 1.2;
        padding-left: 1em;
        margin-left: 1em;
        border-left: 1px solid #979797;
    }

    .mega-menu-column-title {
        font-size: 2rem;
        margin-bottom: 0;
        margin-top: 0;
    }

    /* Megamenu columns */
    #frankenmenu-list .mega-menu .mega-menu-columns {
        float: left;
        width: 75%;
    }

    #frankenmenu-list .mega-menu-preview--collapsed +  .mega-menu-columns {
        width: 100%;
    }

    #frankenmenu-list .mega-menu-columns--1 .mega-menu-column,
    #frankenmenu-list .mega-menu-columns--2 .mega-menu-column {
        width: 48%;
        float: left;
        margin-right: 4%;
    }
    #frankenmenu-list .mega-menu-columns--3 .mega-menu-column {
        width: 31.333%;
        float: left;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-columns--4 .mega-menu-column {
        width: 22%;
        float: left;
        margin-right: 4%;
    }
    #frankenmenu-list .mega-menu-columns--2 .mega-menu-column:last-child,
    #frankenmenu-list .mega-menu-columns--3 .mega-menu-column:last-child,
    #frankenmenu-list .mega-menu-columns--4 .mega-menu-column:last-child {
        margin-right: 0;
    }

    /* Megamenu submenus */
    #frankenmenu-list .mega-menu-submenu .menu-item {
        border-top: 1px solid #D8D8D8;
    }
    #frankenmenu-list .mega-menu-submenu .menu-item:first-child {
        border-top: none;
    }
    #frankenmenu-list .mega-menu-submenu a {
        color: #000;
        padding: 5px 0;
    }
    #frankenmenu-list .mega-menu-submenu a:after {
        content: "\00a0";
        display: inline-block;
        width: 10px;
        height: 1.2em;
        background-image: url(../images/icon_chevron-right.svg);
        background-repeat: no-repeat;
        background-size: 6px auto;
        background-position: bottom right;
    }

    #frankenmenu-list .mega-menu-submenu a:hover,
    #frankenmenu-list .mega-menu-submenu a:focus {
        color: $color-grey-07;
    }

    #frankenmenu-list .mega-menu-submenu a:hover:after,
    #frankenmenu-list .mega-menu-submenu a:focus:after {
        -webkit-animation: arrows-slide .300s cubic-bezier(0.280, 0, 0.720, 1) normal;
        animation: arrows-slide .300s cubic-bezier(0.280, 0, 0.720, 1) normal;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

}

/** Mobile menu only
===================================*/
@media all and (max-width: 991px) {

    /* Prevents font-boosting of menu items on Android */
    #frankenmenu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: 106px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: #FFF;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
        z-index: 1;
    }
    .scrolled #wrap {
        margin-top: 60px;
    }
    #frankenmenu {
        top: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: #fff;
        width: 70%;
        z-index: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
        padding-top: 106px;
        box-shadow: 0 0 4px rgba(0,0,0,0.1), 0 0 20px rgba(0,0,0,0);
        z-index: 2;
    }
    .scrolled #frankenmenu {
        padding-top: 60px;
    }
    .frankenmenu-mob-menu-visible #frankenmenu {
        box-shadow: 0 0 4px rgba(0,0,0,0.1), 0 0 20px rgba(0,0,0,0.2);
    }
    #frankenmenu .container {
        position: relative;
        padding: 0;
        max-width: none;
        width: 100%;
        margin: 0;
        opacity: 0;
    }

    /* ---- Mobile header ---- */
    #mobile-header {
        background-color: #1e5792;
        height: 106px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-bottom: 10px solid #B1C2D4;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
    }

    .scrolled #mobile-header {
        height: 60px;
    }

    #mobile-header .row {
        position: relative;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: 100%;
        align-items: center;
        height: 50px;
    }

    #mobile-header .header__logo {
        margin-top: 12px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        max-width: 120px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 120px;
        flex: 0 0 120px;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
    }

    .mobile-header__login-btn {
        height: 36px;
        padding: 8px 12px 8px;
    }


    .scrolled #mobile-header .header__logo {
        margin-top: 6px;
    }

    #mobile-header .header__logo img {
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
        transform: scale(1);
        transform-origin: top left;
    }

    .scrolled #mobile-header .header__logo img {
        transform: scale(0.5);
    }

    #mobile-header .header__logo a {
        display: block;
    }

    #mobile-header .header__topnav__cart {
        margin-right: 1.75em;
        margin-top: 2px;
    }

    #mobile-header .header__topnav__cart__label {
        display: inline;
    }

    #mobile-menu-button {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        padding: 0;
        text-indent: -9999px;
        width: 40px;
        height: 36px;
        margin-left: 1em;
        position: relative;
        background: none;
    }

    .mobile-menu-button-top,
    .mobile-menu-button-middle,
    .mobile-menu-button-bottom {
        height: 3px;
        background-color: #fff;
        left: 6px;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 400ms;
        animation-duration: 400ms;
        position: absolute;
        display: block;
        right: 6px;
    }
    .mobile-menu-button-top {
        top: 8px;
    }
    .mobile-menu-button-middle {
        top: 17px;
    }
    .mobile-menu-button-bottom {
        top: 26px;
    }

    /* Animations active */
    .frankenmenu-mob-menu-animations .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopClose;
        animation-name: mobileMenuBtnTopClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleClose;
        animation-name: mobileMenuBtnMiddleClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomClose;
        animation-name: mobileMenuBtnBottomClose;
    }

    /* Close button */
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopOpen;
        animation-name: mobileMenuBtnTopOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleOpen;
        animation-name: mobileMenuBtnMiddleOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomOpen;
        animation-name: mobileMenuBtnBottomOpen;
    }



    @-webkit-keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }



    @keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }
    @keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }


    /* ---- All level menu items ---- */
    #frankenmenu-list, #frankenmenu-list ul {
        width: 100%;
    }
    .extra-mobile-menu-items .menu-item,
    #frankenmenu-list .menu-item {
        border-top: 1px solid #f0f0f0;
        float: none;
        width: 100%;
        position: relative;
    }
    .extra-mobile-menu-items .menu-item {
        border-bottom: 1px solid #f0f0f0;
    }

    .extra-mobile-menu-items a,
    #frankenmenu-list a {
        min-height: 39px;
        width: 100%;
        padding-top: 9px;
        padding-right: 55px;
        padding-bottom: 9px;
        color: #2a333e;
        border-left: 3px solid transparent;
    }
    #frankenmenu-list .menu-item.menu-current-item > a {
        color: #2a333e;
        font-weight: bold;
        border-left: 3px solid #51b2e8;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1:last-child {
        // border-bottom: 1px solid #f0f0f0;
    }
    #frankenmenu-list .menu-item-depth1 > a {
        font-size: 1.5rem;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu {
        display: none;
    }
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        display: block;
    }

    /* Depth differences */
    .extra-mobile-menu-items .menu-item-depth1 > a,
    #frankenmenu-list .menu-item-depth1 > a {
        padding-left: 17px;
        background-color: #fff;
    }
    #frankenmenu-list .menu-item-depth2 a {
        font-size: 1.4rem;
    }
    #frankenmenu-list .menu-item-depth2 > a  {
        padding-left: 27px;
    }
    #frankenmenu-list .menu-item-depth3 > a  {
        padding-left: 37px;
    }
    #frankenmenu-list .menu-item-depth4 > a  {
        padding-left: 47px;
    }
    #frankenmenu-list .menu-item-depth5 > a  {
        padding-left: 57px;
    }
    #frankenmenu-list .menu-item-depth6 > a  {
        padding-left: 57px;
    }

    /* Expand buttons */
    .submenu-toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: none;
        color: #4a4949;
        font-size: 2.3rem;
        width: 55px;
        background: none;
        height: 39px;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        padding: 0;
    }
    .submenu-toggle:before,
    .submenu-toggle:after {
        content: " ";
        width: 12px;
        height: 3px;
        display: block;
        position: absolute;
        background-color: #4a4949;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 22px;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
    }
    .submenu-toggle:before {
        -webkit-transform: rotate(45deg) translateX(-5px);
        -ms-transform: rotate(45deg) translateX(-5px);
        transform: rotate(45deg) translateX(-5px);
    }
    .submenu-toggle:after {
        -webkit-transform: rotate(-45deg) translateX(5px);
        -ms-transform: rotate(-45deg) translateX(5px);
        transform: rotate(-45deg) translateX(5px);
    }
    .menu-item-submenu-open > .submenu-toggle:before,
    .menu-item-submenu-open > .submenu-toggle:after {
        top: 15px;
    }
    .menu-item-submenu-open > .submenu-toggle:before {
        -webkit-transform: rotate(-45deg) translateX(-5px);
        -ms-transform: rotate(-45deg) translateX(-5px);
        transform: rotate(-45deg) translateX(-5px);
    }
    .menu-item-submenu-open > .submenu-toggle:after {
        -webkit-transform: rotate(45deg) translateX(5px);
        -ms-transform: rotate(45deg) translateX(5px);
        transform: rotate(45deg) translateX(5px);
    }

    /* ---- Sub-navigation ---- */
    #frankenmenu-subnav-list {
        display: none;
    }

    /* ---- Menu positions ---- */

    /* Left */
    .frankenmenu-mobile-pos-left #frankenmenu {
        left: 0;
    }
    .frankenmenu-mobile-pos-left #frankenmenu .container {
        -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
        transform: translate3d(-10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
        -webkit-transform: translate3d(70%, 0, 0);
        transform: translate3d(70%, 0, 0);
    }

    /* Right */
    .frankenmenu-mobile-pos-right #frankenmenu {
        right: -70%;
    }
    .frankenmenu-mobile-pos-right #frankenmenu .container {
        -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
        transform: translate3d(10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
        -webkit-transform: translate3d(-70%, 0, 0);
        transform: translate3d(-70%, 0, 0);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #frankenmenu {
        right: 0;
    }
    .frankenmenu-mobile-pos-right #mobile-menu-button {
        float: right;
    }

    /* ---- Transitions ---- */
    #frankenmenu,
    #wrap,
    #frankenmenu .container {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
    }
    .js #frankenmenu {
        opacity: 0;
    }

    /* Menu open */
    .frankenmenu-mob-menu-visible #frankenmenu {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .frankenmenu-mob-menu-visible #frankenmenu .container {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        transform: translate3d(0, 0, 0) scale(1);
    }

    /* ---- Moved elements ---- */
    .frankenmove {
        display: none;
    }

    /* ---- Megamenu ---- */
    .mega-menu-preview,
    .mega-menu-preview-row {
        display: none;
    }
    .mega-menu-preview-image {
        background-image: none !important;
    }
    .mega-menu-column-title {
        color: #FFF;
        font-size: 1.6rem;
        line-height: inherit;
        padding: 6px 20px;
        margin: 0;
        background: rgba(0,0,0,0.3);
    }
    #frankenmenu-list .mega-menu {
        display: none;
    }
    #frankenmenu-list .mega-menu ul {
        display: block;
    }

    .menu__link__search svg {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        vertical-align: middle;
        margin-bottom: 2px;
    }

    .menu-item-depth1--item6 + .menu-item {
        display: none;
    }
}

@media screen and (max-width: 34.9375em) { /* 559px */
    #mobile-header .header__topnav__cart__label {
        display: none;
    }

    #mobile-header .header__topnav__cart {
        margin-right: 10px;
        width: 32px;
        text-align: center;
    }
}

/* BP large */
@media screen and (min-width: 75em) { /* 1200px */

    #frankenmenu-list .menu-item-depth1 > a {
        margin: 0 $spacing*2;
    }

    #frankenmenu-list .menu-item-depth1:first-child > a {
        margin-left: 0;
    }

    #frankenmenu-list .menu-item-depth1:last-child > a {
        margin-right: 0;
    }

}

@media screen and (min-width: 92em) { /* 1472px */



    .primary-nav {
        width: calc(100% - 140px);
        margin-top: 0;
    }

    #frankenmenu-list {
        display: flex;
        justify-content: flex-end;
    }

    /* ---- megamenu ---- */
    #frankenmenu-list .mega-menu {
        left: 0;
    }

    #frankenmenu-list .menu-item .mega-menu .container {
        padding-left: 5em;
        padding-right: 5em;
    }
}
