
.home-banner {
    background: $color-white;

    /* BP large */
    @media screen and (min-width: 75em) { /* 1200px */
        //IE11 grid fallback
        @include clearfix;
        @supports (display: grid) {
            &:before {
                display: none;
            }
        }

        display: grid;
        grid-template-columns: 64% auto;

        &__simple-cards {
            padding-right: $spacing*2;
        }
    }

    .promos {
        position: relative;
        padding-bottom:  $spacing;

        @media screen and (min-width: 75em) { /* 1200px */
            //IE11 grid fallback
            max-width: 64%;
            float: left;
            @supports (display: grid) {
                max-width: none;
            }
        }

        &__simple-cards {
            margin-top: -8%;
            position: relative;

            /* BP small */
            @media screen and (min-width: 48em) { /* 768px */
                margin-top: -10%;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }


            @media screen and (min-width: 75em) { /* 1200px */
                padding-right: $spacing;
            }

            @media screen and (min-width: 75em) and (max-width: 91.9375em) { /* 1200px - 1471px */
                margin-top: -12%;

                .card__heading {
                    font-size: 2rem;
                }
            }

            &__item {
                padding: 0 $spacing*2;

                @media screen and (min-width: 48em) { /* 768px */
                    //IE11 grid fallback
                    float: left;
                    width: 50%;

                    // IE11 flex/grid bug workaround
                    .card__img,
                    .card__img img {
                        max-height: 206px;
                    }
                    // reset for non-buggy browsers
                    @supports (display: flex) {
                        width: auto;
                        .card__img,
                        .card__img img {
                            max-height: none;
                        }
                    }

                }
            }
        }

        &:before,
        &:after {
            content: '\00a0';
            position: absolute;
            bottom: 0;
            width: 50%;
            height: $spacing;
        }

        &:before {
            background: #534bbd;
            left: 0;
        }

        &:after {
            background: #53af48;
            right: 0;
        }

        /* BP large */
        @media screen and (min-width: 75em) { /* 1200px */

            &:after {
                background: #53af48;
                right: 0;
                width: calc(50% + 4px);
            }
        }
    }

}


.hm-blog-feed {
    padding: 2em 1em;
    border-bottom: $spacing solid $color-grey-02;
    margin-bottom: $spacing*2;

    display: flex;
    flex-flow: column nowrap;

    &__header {

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        &__btns {
            text-transform: uppercase;
            text-align: right;

            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .two-part-heading {
            position: relative;
            top: $spacing;

            @media screen and (min-width: 48em) { /* 768px */
                padding-top: $spacing;
            }
        }
    }

    .media-object-list {
        flex: 1 0 auto;

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        &:before {
            content: none;
        }
    }

    .media-object__heading {
        @media screen and (min-width: 75em) and (max-width: 91.9375em) { /* 1200px - 1471px */
            font-size: 2rem;
        }
    }

    &__footer {
        padding-top: 2em;
        border-top: 2px solid $color-grey-02;

        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: $spacing*4;
        padding-right: 5em;

        border-left: 1px solid $color-grey-02;
        margin-bottom: 0;
    }
    @media screen and (min-width: 92em) {/* 1472px */
        padding-left: 3em;
    }

}


.section--events {
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: $spacing solid $color-grey-02;
    margin-bottom: $spacing*2;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: 48em) { /* 768px */
        padding-left: 3em;
        padding-right: 3em;
        margin-bottom: $spacing*4;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
        margin-bottom: $spacing*6;
    }

    &__header {
        margin-bottom: $spacing*2;

        @media screen and (min-width: 48em) { /* 768px */
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }

    .two-part-heading {
        margin-bottom: 24px;

        @media screen and (min-width: 48em) { /* 768px */
            margin-bottom: 64px;
        }

        & span {
            position: relative;
            display: inline-block;
            padding-bottom: 24px;

            @media screen and (min-width: 48em) { /* 768px */
                padding-bottom: 32px;
            }

            &:after {
                content: '\00a0';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 6px;
                background: $color-secondary;
            }
        }
    }
}

.section--stats,
.section--quick-links {
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: $spacing solid $color-grey-02;
    margin-bottom: $spacing*2;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: 48em) { /* 768px */
        padding-left: 3em;
        padding-right: 3em;
        margin-bottom: $spacing*4;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
        margin-bottom: $spacing*6;
    }
}
