.section--header {
    background: $color-secondary;
    color: $color-white;
    position: relative;
    padding: $spacing--xlarge 0 $spacing--xlarge;
    display: none;
    transition: all $link-transition;

    .scrolled & {
        padding-top: $spacing*1.5;
        padding-bottom: $spacing*1.5;
    }

    @media screen and (min-width: 62em) { /* 992px */
        display: block;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 10px;
        background: rgba($color-secondary, .3);
        transition: all $link-transition;
    }

    .scrolled  &:after {
        height: 6px;
    }

    .header__logo {
        display: none;

        @media screen and (min-width: 62em) { /* 992px */

            display: block;
            max-width: 73px;
        }

        @media screen and (min-width: 92em) {/* 1472px */
            max-width: 133px;
        }
        a {
            display: block;
        }
    }


}


.container--header {
    @media screen and (min-width: 92em) {/* 1472px */
        padding-left: 5em;
        padding-right: 5em;
    }

}



.header {

    &__logo {
        float: left;
    }

    &__topnav {
        float: right;
        margin-bottom: $spacing*2;
        display: flex;
        align-items: center;

        font-size: 1.4rem;
        text-transform: uppercase;
        :where(ul) {
            list-style: none;
            display: inline-block;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
            }
        }
        &__list {
            &__item {
                border-right: 2px solid $color-primary;
                padding: 0 $spacing*2;
            }
            &__item--members {
                border: 0px;
                padding-right: 0;
                text-align: left;

                :where(ul) {
                    display: block;
                }

                @media screen and (min-width: 35em) { /* 560px */
                    :where(ul) {
                        display: inline-block;
                    }
                }
            }
        }
        &__link {
            color: $color-white;
            font-weight: bold;

            svg {
                display: block;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-primary;

                & svg path {
                    fill: $color-primary;
                }
            }
        }
        &__cart {
            font-weight: normal;
            text-decoration: none;

            &__label{
                display: none;
            }

            @media screen and (min-width: 35em) { /* 560px */
                &__label{
                    display: inline;
                }
            }
        }

        &__button-list {
            @media screen and (min-width: 35em) { /* 560px */
                display: flex;
                gap: $spacing;
            }
        }

        &__btn {

            &:hover,
            &:focus,
            &:active {
                background-color: $color-primary;
                border-color: $color-primary;
            }
        }
    }

    .primary-nav {
        clear: right;
        float: right;

        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 62em) { /* 992px */
    .scrolled #header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }

    .scrolled #wrap {
        margin-top: 145px;
    }
}

