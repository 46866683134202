
.content {
    background: $color-white;
    padding-top: $spacing*4;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom: $spacing solid $color-grey-02;
    margin-bottom: $spacing*2;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    @media screen and (min-width: 48em) { /* 768px */
        padding-left: 3em;
        padding-right: 3em;
        margin-bottom: $spacing*4;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        padding-top: $spacing*6;
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
        margin-bottom: $spacing*6;

        //IE11 Grid fallback
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        display: grid;
        grid-template-columns: 1fr calc(25.4% - 2.5em); /* matches inner banner */
        grid-gap: $spacing*5;

        // IE 11 grid fallback
        .sidebar {
            min-width: calc(25.4% - .5em);
            padding-left: 2em;

            @supports (display: grid) {
                padding-left: 0;
            }
        }
    }
    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        grid-gap: $spacing*12;
    }
}

.wide .content {
    @media screen and (min-width: 75em) { /* 1200px */
        grid-template-columns: 100%;
    }
}

.mainbar {
    margin-bottom: 40px;

    @media screen and (min-width: 75em) { /* 1200px */
        margin-bottom: 0;
    }
}
