.card-list {
    padding: 0;
    margin: 0;
    list-style: none;

    @media screen and (min-width: 48em) { /* 768px */
        //IE11 grid fallback
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &__item {
        margin-top: $spacing*2;
        //IE11 grid fallback
        width: 33.33%;
    }
}

@supports(display:grid) {
    .card-list {
        display: grid;
        grid-gap: $spacing--xlarge;

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            grid-template-columns: 1fr 1fr;
        }

        /* BP xlarge */
        @media screen and (min-width: 92em) {/* 1472px */
            grid-template-columns: 1fr 1fr 1fr 1fr ;
        }
    }
    .card-list__item {
       margin-top: 0;
       //IE11 grid fallback
       width: auto;
    }

   .card-list--events {

        /* BP small */
        @media screen and (min-width: 48em) { /* 768px */
            grid-template-columns: 1fr 1fr 1fr 1fr;

            & .card-list__item {
                grid-column: span 2;
            }
        }



        @media screen and (min-width: 92em) {/* 1472px */
            grid-template-columns: 1fr 1fr 1fr;

            & .card-list__item {
                grid-column: span 1;
            }

            &.card-list--single-row .card-list__item:nth-child(n+4) {
                display: none;
            }
        }
   }
}



.two-col-section-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    :where(.button) {
        text-transform: uppercase;
    }
}

.card-list--membership {



    @media screen and (min-width: 92em) {/* 1472px */

        grid-template-columns: repeat(5, 1fr);
        gap: $spacing*4;
    }


    @media screen and (min-width: 48em) and (max-width: 91.9375em) { /* 768px - 1471px */
        // .card-list__item:last-child:nth-child(5) .card {
        //     transform: translateX(calc(50% + 16px));
        // }
        .card-list__item:last-child:nth-child(5) {
            grid-column: 1 / -1;
        }
    }

    .card__img__icon {
        background: no-repeat center center;
        background-size: contain;
    }

    .card-list__item:nth-child(1) .card__img__icon {
        background-image: url(../images/icon-system/icon_personal.svg);
    }

    .card-list__item:nth-child(2) .card__img__icon {
        background-image: url(../images/icon-system/icon_early-career.svg);
    }

    .card-list__item:nth-child(3) .card__img__icon {
        background-image: url(../images/icon-system/icon_school.svg);
    }

    .card-list__item:nth-child(4) .card__img__icon {
        background-image: url(../images/icon-system/icon_corporate.svg);
    }

    .card-list__item:nth-child(5) .card__img__icon {
        background-image: url(../images/icon-system/icon_total.svg);
    }
}
