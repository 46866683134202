
.section--quick-links {
    background: $color-white;
    padding-left: 10px;
    padding-right: 10px;

    .two-part-heading {
        margin-bottom: 24px;

        @media screen and (min-width: 48em) { /* 768px */
            margin-bottom: 64px;
        }

        & span {
            position: relative;
            display: inline-block;
            padding-bottom: 24px;

            @media screen and (min-width: 48em) { /* 768px */
                padding-bottom: 32px;
            }

            &:after {
                content: '\00a0';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 6px;
                background: $color-secondary;
            }
        }
    }
}

.section--subscribe {
    background: $color-grey-07;
    @include reverse-text;
    padding-left: 10px;
    padding-right: 10px;


    & .two-part-heading__part-2 span {
        position: relative;
        display: inline-block;

        &:after {
            content: '\00a0';
            position: absolute;
            top: -36px;
            left: 0;
            right: 0;
            height: 6px;
            background: $color-grey-02;

            @media screen and (min-width: 22.5em) {/* 360px */
                top: -50px;
            }

            @media screen and (min-width: 22.5em) {/* 360px */
                top: -59px;
            }

            @media screen and (min-width: 48em) { /* 768px */
                top: -89px;
            }
        }

    }

}


.subscribe {

    &__enews {

        &__heading {
            text-transform: uppercase;
            line-height: 1.3;
        }
        .button {
            width: 50%;
            min-width: 45px;
            max-width: 145px;
            gap: 8px;
        }
    }

    &__socials {
        margin: $spacing*4 0 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &__heading {
            text-transform: uppercase;
            line-height: 1.3;
            flex: 1 0 auto;
            margin: 0;
        }
    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */
        &__header {
            display: grid;
            grid-template-columns: 25% auto;
            align-items: end;
            align-items: last baseline;
        }
        &__enews {
            width: 100%;

            &__heading {

            }

            &__form {
                flex: 1 1 auto;
                gap: 0.5%;

                .button {
                    width: 44px;
                }
            }
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        &__header {
            grid-template-columns: 23% auto;
        }
    }

    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        //IE11 grid fallback
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        grid-gap: $spacing*4;

        &__header {
            grid-area: 1 / span 11;
        }
        &__enews {
            grid-column: 1 / span 9;
        }
        &__socials {
            margin-top: 0;
            grid-column: 10 / span 3;

            .social-list {
                margin: 0;
            }
        }

        &__header {
            grid-template-columns: 18% auto;
        }
    }

}

.acknowledge-of-country {
    padding-top: $spacing*4;
    margin-top: $spacing*2;
    border-top: 1px solid $color-white;
    color: rgba($color-white, .6);
    font-weight: bold;
    grid-column-start: 1;
    grid-column-end: 13;

}

.section--contact-us {
    background: $color-grey-02;

    .section {
        padding-left: 10px;
        padding-right: 10px;
    }



}

.contact-us {

    &__heading {
        color: $color-primary;
        font-size: 2.4rem;
    }

    &__map {
        background: url(../images/img_location.png) top 20% center no-repeat;
        background-size: cover;
        padding-top: 60%;
        //IE11 Grid fallback
        display: none;

        @supports (display: grid) {
            display: block;
        }
    }
/* BP small */
    @media screen and (min-width: 48em) { /* 768px */

        &__details {
            &__text-cols {
                //IE11 Grid fallback
                display: flex;
                flex-flow: row nowrap;

                display: grid;
                grid-template-columns: auto auto auto;
                grid-gap: $spacing*3;

                p {
                    border-right: 1px solid $color-grey-05;
                    //IE11 Grid fallback
                    padding: 0 $spacing*2;
                    @supports (display: grid) {
                        padding: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }
                }
            }

        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        //IE11 grid fallback
        display: flex;
        flex-flow: row nowrap;

        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: $spacing*4;
    }

    /* BP xlarge */
    @media screen and (min-width: 92em) {/* 1472px */
        grid-template-columns: 364px auto;

        &__details {
            &__text-cols {
                grid-gap: $spacing*4;
            }

        }
    }
}

.terms-footer {
    font-size: 1.5rem;

    &-sm-links {
        display: block;
        margin-top: $spacing*2;

        a {
            text-decoration-color: transparent;
        }

        a:hover,
        a:focus,
        a:active {
            text-decoration-color: inherit;
        }
    }

    &__memberships {
        font-family: $accent-font;

        &__logo {
            max-height: 60px;
            width: auto;
        }

        &__logo--asta {
            margin-left: $spacing*2.5;
        }
    }

    /* BP small */
    @media screen and (min-width: 48em) { /* 768px */


        &__memberships {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &__logo {
                margin-left: $spacing*4;
            }
        }
    }
    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        //IE11 grid fallback
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: $spacing*2;
        align-items: center;

        p {
            margin: 0;
        }

    }

    @media screen and (min-width: 92em) {/* 1472px */
        &-sm-links {
            display: inline;
            margin-top: 0;
        }

    }
}

.section--terms-footer {
    background: $color-secondary;
    @include reverse-text;
    padding-left: 1em;
    padding-right: 1em;
}

@media screen and (min-width: 22.5em) {/* 360px */

    .section--quick-links {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--subscribe {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--contact-us .section {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .section--terms-footer {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

}

@media screen and (min-width: 48em) { /* 768px */

    .section--quick-links {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--subscribe {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--contact-us .section {
        padding-left: 3em;
        padding-right: 3em;
    }

    .section--terms-footer {
        padding-left: 3em;
        padding-right: 3em;
    }

}

@media screen and (min-width: 75em) { /* 1200px */

    .section--quick-links {
        padding-left: 5em;
        padding-right: 5em;
        padding-bottom: 6em;
    }

    .section--subscribe {
        padding-left: 5em;
        padding-right: 5em;
    }

    .section--contact-us .section {
        padding-right: 5em;
        //IE11 grid fallback
        padding-left: 5em;
        @supports (display: grid) {
            padding-left:0;
        }
    }

    .section--terms-footer {
        padding-left: 5em;
        padding-right: 5em;
    }

}
